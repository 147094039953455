import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { navigate } from '@reach/router'
import { string, func, arrayOf, bool, number } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Button from './Button'
import Input from './Input'
import Text from './Text'
import Dropdown from './Dropdown'
import OperatorComponent from './OperatorComponent'

import {
  SHADOW_GREY,
  LIGHT_GREY,
  BLUE_GREY,
  WHITE,
  TEXT_BIG,
  sharedStatusOptions,
  VERMILION,
  PLATINUM,
  METAL,
  SILVER,
} from '../../constants'
import { inputString } from '../../formValidationSchemas'
import {
  outgoingShape,
  ruleShape,
  onActionChangesShape,
  updateChangesShape,
} from '../../types'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400

const Wrapper = styled.div`
  background: ${PLATINUM};
  box-shadow:${SHADOW_GREY};
  padding: 30px 20px;
  border-radius:5px
  max-width: 1300px;
  margin-top: 20px;
`

const Heading = styled.div`
  background: ${VERMILION};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px;
  box-shadow: ${SHADOW_GREY};
`

const DetailForm = styled.div`
  background: ${WHITE};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  box-shadow: ${SHADOW_GREY};
`

const NameStatus = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
  margin-bottom: 10px;
`

const ThenOperator = styled.div`
  border-top: 1px solid ${VERMILION};
  margin-top: 40px;
  padding-top: 20px;
`

const SaveCancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const StyledInput = styled(Input)`
  font-family: 'DM Sans';
  max-width: 600px;
  font-size: ${TEXT_BIG};
`

const RowDiv = styled.div`
  display: flex; /* establish flex container */
  flex-direction: row; /* default value; can be omitted */
  flex-wrap: nowrap; /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
`
const OptimizationType = {
  Exclusion: 'Exclusion',
  Transformation: 'Transformation',
  Alert: 'Alert',
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`

const OptimizationDetail = ({
  addNewAction,
  addNewCondition,
  columnList,
  dispatch,
  getActionValue,
  getConditionList,
  getConditionValue,
  getConditionChildValue,
  getListOfActions,
  hideColumnList,
  inputId,
  mainLabel,
  masterText,
  onActionChanges = {},
  onRemoveCondition,
  onSave,
  outputCols,
  partnerText,
  saveLink,
  selectedOutgoing,
  statusDataId,
  type,
  updateChanges,
  maxConditions,
  conditionalText,
  actionButtonText,
  multipleActionButtonText,
  actionText,
}) => {
  const { t } = useTranslation()

  const [mapList, setMapList] = useState([])
  useEffect(
    () => {
      setMapList(type.expressions ? type.expressions : type.conditions)
    },
    [type, type.conditions, type.expressions]
  )

  const {
    onActionColumnChange,
    onActionFirstValueChange,
    onActionOptionsTypeChange,
    onActionSecondValueChange,
    onActionSecondOptionsTypeChange,
    onActionOperatorChange,
    onActionRemove,
    onActionTypeChange,
  } = onActionChanges
  const {
    updateCaseSensitive,
    updateName,
    updateOperator,
    updateSource,
    updateStatus,
    updateValue,
    updateValueType,
  } = updateChanges

  const arrayOfActions = (options, type, column) => {
    const firstType =
      options && options.input && options.input[0] && options.input[0].type
    const secondType =
      options && options.input && options.input[2] && options.input[2].type

    return [
      {
        value: getActionValue(type, options, true) || '',
        function: onActionFirstValueChange,
      },
      {
        value: options ? options.type || firstType : undefined,
        function: onActionOptionsTypeChange,
      },
      {
        value: getActionValue(type, options, false, false, column) || '',
        function: onActionSecondValueChange,
      },
      {
        value: options ? options.type || secondType : undefined,
        function: onActionSecondOptionsTypeChange,
      },
      {
        value: getActionValue(type, options, false, true),
        function: onActionOperatorChange,
      },
    ]
  }

  const alertItem = type
  if (!type) return null

  return (
    <Wrapper>
      <Heading>
        <Text color={WHITE} size={TEXT_BIG}>
          {type.name}
        </Text>
        <Text color={WHITE} size={TEXT_BIG}>
          {selectedOutgoing ? partnerText : masterText}
          {selectedOutgoing && `: ${selectedOutgoing.name}`}
        </Text>
      </Heading>
      <DetailForm>
        <Formik
          enableReinitialize
          initialValues={{
            name: type.name,
            status: type.enabled ? 'active' : 'paused',
          }}
          validationSchema={inputString}
        >
          {({ errors }) => (
            <NameStatus>
              <StyledInput
                label={mainLabel}
                name="name"
                data-id={inputId}
                onBlur={updateName}
                maxLength="100"
              />
              {errors.name}
              <Dropdown
                label={t('status')}
                name="status"
                isHigh
                onChange={updateStatus}
                options={sharedStatusOptions(t)}
                data-id={statusDataId}
              />
              {errors.status}
            </NameStatus>
          )}
        </Formik>
        {mapList &&
          mapList.map(({ options, type, id }, index) => {
            const conditionValue = getConditionValue(type, options)
            const childValue = getConditionChildValue
              ? getConditionChildValue(type, options)
              : options.type

            return (
              <OperatorComponent
                caseSensitive={options && options.caseSensitive}
                dataId="if-operator-dropdown"
                hideColumnList={hideColumnList}
                columnList={columnList}
                hasFullHeight
                index={index}
                key={`${index}_${type}`}
                label={t('column')}
                outputCols={outputCols}
                removeClick={onRemoveCondition}
                selectedOperator={type}
                selectedSource={
                  options && options.source
                    ? options.source.replace(/[{$}]/g, '')
                    : ''
                }
                t={t}
                text={'if'}
                treeToRender={getConditionList(
                  conditionValue == null ? '' : conditionValue,
                  updateValue,
                  childValue,
                  updateValueType
                )}
                updateCaseSensitive={updateCaseSensitive}
                updateOperator={updateOperator}
                updateSource={updateSource}
              />
            )
          })}
        {mapList && mapList.length === 0 && (
          <Text size={TEXT_BIG}>{t('conditionals')}</Text>
        )}
        <RowDiv>
          {mapList && (!maxConditions || mapList.length < maxConditions) && (
            <StyledButton
              data-id="add-condition"
              onClick={() => addNewCondition(type, dispatch)}
              theme={SECONDARY_BUTTON}
            >
              {mapList && mapList.length > 0 ? t('add_if') : t('add_condition')}
            </StyledButton>
          )}
          {mapList.length > 0 && conditionalText ? (
            <Text size={TEXT_BIG}> {t(conditionalText)}</Text>
          ) : (
            ''
          )}
        </RowDiv>
        {type.actions && (
          <ThenOperator>
            {type.actions.map(({ column, type, options }, index) => {
              const columnNeat = column && column.replace(/[{$}]/g, '')
              const actionsOptions = getListOfActions(
                arrayOfActions(options, type, column)
              ).filter(a => !a.onFilter || a.onFilter(alertItem))

              return (
                <OperatorComponent
                  caseSensitive={options && options.caseSensitive}
                  hideColumnList={hideColumnList}
                  columnList={columnList}
                  index={index}
                  key={`${index}_${column}_${type}`}
                  label={t('column')}
                  outputCols={outputCols}
                  removeClick={onActionRemove}
                  selectedOperator={type}
                  selectedSource={column ? columnNeat : column}
                  t={t}
                  text="then"
                  treeToRender={actionsOptions}
                  updateCaseSensitive={updateCaseSensitive}
                  updateOperator={onActionTypeChange}
                  updateSource={onActionColumnChange}
                />
              )
            })}
            <RowDiv>
              <StyledButton
                dataId="add-rule"
                onClick={() => addNewAction(type, dispatch)}
                theme={SECONDARY_BUTTON}
              >
                {type.actions.length > 0
                  ? t(multipleActionButtonText)
                  : t(actionButtonText)}
              </StyledButton>
              {type.actions.length > 0 && actionText ? (
                <Text size={TEXT_BIG}> {t(actionText)}</Text>
              ) : (
                ''
              )}
            </RowDiv>
          </ThenOperator>
        )}
      </DetailForm>
      <SaveCancelWrapper>
        <StyledButton
          dataId="get-back"
          onClick={() => navigate(saveLink)}
          theme={SECONDARY_BUTTON}
        >
          {t('button_back')}
        </StyledButton>
        <Button onClick={onSave} dataId="save-changes">
          {t('button_save')}
        </Button>
      </SaveCancelWrapper>
    </Wrapper>
  )
}

OptimizationDetail.propTypes = {
  hideColumnList: bool,
  addNewAction: func,
  addNewCondition: func.isRequired,
  columnList: arrayOf(string),
  dispatch: func.isRequired,
  getActionValue: func,
  getConditionList: func.isRequired,
  getConditionValue: func.isRequired,
  getConditionChildValue: func,
  getListOfActions: func,
  inputId: string.isRequired,
  mainLabel: string.isRequired,
  masterText: string.isRequired,
  onActionChanges: onActionChangesShape,
  onRemoveCondition: func.isRequired,
  onSave: func.isRequired,
  outputCols: arrayOf(string),
  partnerText: string.isRequired,
  saveLink: string.isRequired,
  selectedOutgoing: outgoingShape,
  statusDataId: string.isRequired,
  type: ruleShape.isRequired,
  updateChanges: updateChangesShape.isRequired,
  maxConditions: number,
}
export default OptimizationDetail
