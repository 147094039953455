import React from 'react'
import Table from 'react-table'
import styled from 'styled-components'
import { string, number } from 'prop-types'
import 'react-table/react-table.css'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400
import { METAL, SILVER, VERMILION, WHITE } from '../../constants'

const Wrapper = styled.div`
  padding-top: 10px;
  font-family: Dm Sans;
  text-color: ${METAL};

  & .ReactTable {
    border-color: ${METAL};
    background: ${WHITE};
    border-radius: 5px;
    border-width: 1px;

    .rt-thead .rt-th {
      background: ${VERMILION};
      border-color: #9b9b9d;
      padding: 16px;
      font-weight: bold;
      color: ${WHITE};
      border-bottom: 1px solid;
    }

    .rt-thead.-header {
      box-shadow: None;
    }

    .rt-thead {
      background: ${METAL};
      border-color: ${WHITE};
      border-width: 1px;
    }

    .rt-thead .rt-th {
      background: ${METAL};
      border-color: ${WHITE};
      border-width: 1px;
      padding: 16px;
      font-weight: bold;
    }

    .rt-tbody .rt-tr-group {
      border-color: ${SILVER};
    }

    .rt-tbody .rt-td {
      border-color: ${SILVER};
    }

    .rt-tbody .rt-tr {
      display: flex;
      flex-direction: row;
    }

    .rt-td {
      padding: 16px;
      align-items: left;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ReactTable .rt-noData {
    z-index: 0 !important;
    background: #00000000;
    border-radius: 5px;
    color: ${VERMILION};
    font-weight: bold;
  }
`

const StyledTable = props => (
  <Wrapper>
    <Table
      {...props}
      className={props.className || '-highlight'}
      defaultPageSize={props.defaultPageSize || 10}
      noDataText={props.t('no_rows_found')}
      minRows={0}
    />
  </Wrapper>
)

StyledTable.propTypes = {
  className: string,
  defaultPageSize: number,
}

export default StyledTable
