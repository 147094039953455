import React from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { number, func, object, bool } from 'prop-types'
import Text from './Text'
import { WHITE, SHADOW_GREY, VERMILION, METAL, SILVER } from '../../constants'
import ErrorBoundary from './ErrorBoundary'
import { structuredTableShape, structuredDataShape } from '../../types'
import '@fontsource/dm-sans' // Defaults to weight 400

const MainTable = styled(ReactTable)`
  border: none !important;
  color: ${METAL};
  font-family: 'Dm Sans';
  overflow: auto contain;

  .rt-thead .rt-tr {
    padding: 10px 0;
    font-weight: bold;
    text-align: left !important;
    .rt-th {
      border: none;
    }
    .rt-resizable-header-content {
      color: ${VERMILION};
      display: flex;
    }
  }

  .rt-tbody {
  }

  .rt-tbody .rt-td-expandable,
  .rt-tbody .rt-tr-group-expandable {
    border-radius: 5px;
    box-shadow: ${SHADOW_GREY};
  }

  .rt-tbody
    .rt-td-expandable
    ~ .ReactTable
    .rt-tbody
    > .rt-tr-group:nth-last-child(1) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rt-tbody
    .rt-td-expandable
    ~ .ReactTable
    .rt-tbody
    > .rt-tr-group:nth-child(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .rt-tbody .rt-td {
    border-right: none !important;
    text-overflow: ellipsis !important;
    display: flex;
    align-items: center;
  }

  ${({ isUsersTable }) =>
    isUsersTable &&
    `.rt-tbody .rt-td:last-child {
      display: flex;
      justify-content: flex-end;
    }
`}

  .rt-tbody > .rt-tr-group {
    margin-bottom: 10px;
    background: ${WHITE};
    box-shadow: ${SHADOW_GREY};
    line-height: 1.8;
    ${({ isUsersTable }) => isUsersTable && 'padding: 0 10px'};
  }

  .rt-tbody .rt-tr-group {
    border-bottom: solid 0px rgba(0, 0, 0, 0.05) !important;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TableWrapper = styled.div`
  .ReactTable .rt-noData {
    z-index: 0 !important;
    background: #00000000;
    border-radius: 5px;
    color: ${SILVER};
    margin-top: 20px;
    font-weight: bold;
  }
`

const ButtonWrapper = styled.div`
  transform: ${({ isPrev }) => (isPrev ? 'rotate(-135deg)' : 'rotate(45deg)')};
  padding: 9px;
  box-shadow: 3px -3px 0 0 ${VERMILION};
  margin: 14px;
`

const StyledButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 8px;
  ${({ disabled }) => !disabled && 'cursor: pointer'};
  outline: none;
`

const PagesNumber = styled.div`
  display: inline-block;
  position: relative;
  top: 13px;
`

const StructuredTable = ({
  columns,
  data,
  expanded,
  getTrGroupProps,
  getTrProps,
  isUsersTable,
  onExpandedChange,
  page,
  pageSize,
  pageTotal,
  selectNextPage,
  selectPreviousPage,
  SubComponent,
}) => (
  <ErrorBoundary>
    <Wrapper>
      <ButtonWrapper isPrev>
        <StyledButton
          onClick={selectPreviousPage}
          disabled={page === 0 || page === null}
        />
      </ButtonWrapper>
      <PagesNumber>
        <Text bold>{`${page + 1} of ${pageTotal} `}</Text>
      </PagesNumber>

      <ButtonWrapper>
        <StyledButton
          onClick={selectNextPage}
          disabled={page + 1 === pageTotal || pageTotal === null}
        />
      </ButtonWrapper>
    </Wrapper>
    <TableWrapper>
      <MainTable
        columns={columns}
        data={data}
        expanded={expanded}
        getTrGroupProps={getTrGroupProps}
        getTrProps={getTrProps}
        isUsersTable={isUsersTable}
        onExpandedChange={onExpandedChange}
        pageSize={pageSize}
        resizable={false}
        showPagination={false}
        sortable={false}
        SubComponent={SubComponent}
        minRows={1}
      />
    </TableWrapper>
  </ErrorBoundary>
)

StructuredTable.propTypes = {
  columns: structuredTableShape,
  data: structuredDataShape,
  expanded: object,
  getTrProps: func,
  isUsersTable: bool,
  onExpandedChange: func,
  page: number,
  pageSize: number.isRequired,
  pageTotal: number,
  selectNextPage: func.isRequired,
  selectPreviousPage: func.isRequired,
  SubComponent: func,
}
export default StructuredTable
