import React, { Fragment, useReducer, useEffect, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  fetchOutgoingMapping,
  updateOutgoingMapping,
  updateMappings,
} from './actions'
import { reducer, initialState } from './reducer'
import {
  APP_STATE,
  LAPTOP_WIDTH,
  MAX_CONTENT_WIDTH,
  METAL,
  LIGHT_GREY,
} from '../../../constants'
import { cancelable } from '../../../lib/signal'

import { outgoingShape } from '../../../types'

import Button from '../../shared/Button'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import ModalWindow from '../../shared/ModalWindow'
import MappingList from '../../shared/MappingList'
import Tooltip2 from '../../shared/Tooltip2'

const Spacer = styled.div`
  padding-top: 10px;
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: ${MAX_CONTENT_WIDTH};

  @media (max-width: ${LAPTOP_WIDTH}) {
    grid-template-columns: 1fr;
  }
`

const ColumnWrapper = styled.div`
  background: ${({ bgColor }) => bgColor};
  border-radius: 5px;
  padding: 15px;
`

const ButtonContainer = styled.div`
  width: ${LAPTOP_WIDTH};
  padding: 20px;
  text-align: right;
`

const ModalText = styled.div`
  max-width: 300px;
`

const MappingContent = ({ feedId, selectedOutgoing }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const { t } = useTranslation()

  useEffect(
    () => {
      if (!selectedOutgoing) return
      cancelable(signal => {
        if (selectedOutgoing) {
          fetchOutgoingMapping(feedId, selectedOutgoing.id, dispatch, t, signal)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing]
  )

  const { headers, outgoingMapping, errorMsg, stateOfAction } = state

  const requiredButEmpty = value =>
    value &&
    value.system &&
    value.system.required &&
    value.options.name.length === 0

  const missingRequiredFields = () => {
    const findRequiredAndEmpty = list =>
      Object.entries(list).filter(value => requiredButEmpty(value[1]))

    const isRequiredAll = [...findRequiredAndEmpty(outgoingMapping)]

    return isRequiredAll.map(item => item[0])
  }

  const saveChanges = needsChecking => {
    if (needsChecking && missingRequiredFields().length > 0) {
      setDeleteDialogVisible(true)
    } else {
      setDeleteDialogVisible(false)
      updateMappings(feedId, selectedOutgoing, outgoingMapping, dispatch, t)
    }
  }

  if (
    stateOfAction === APP_STATE.FETCHING ||
    !outgoingMapping ||
    !headers ||
    !selectedOutgoing
  ) {
    return <Loader />
  }
  if (stateOfAction === APP_STATE.ERROR) return <p>{errorMsg}</p>

  return (
    <Fragment>
      <PageDescription
        heading={t('outgoing_field_transformer')}
        description={t('field_transformer_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: 'button_save_changes',
            onClick: saveChanges,
            tooltip: t('mapping_button_save_tooltip'),
          },
        ]}
      />
      <TopButtonsWrapper />
      <Spacer />
      <GridWrapper>
        <ColumnWrapper bgColor={LIGHT_GREY}>
          <MappingList
            addButtonText={t('button_add_custom')}
            description={t('partner_feed_description')}
            dispatch={dispatch}
            firstTitle={selectedOutgoing.name}
            keys={headers.map(row => ({
              id: row,
              name: row.replace('master_', ''),
            }))}
            mapping={outgoingMapping}
            requiredButEmpty={requiredButEmpty}
            secondTitle={t('master_feed')}
            showHeading
            updateMapping={updateOutgoingMapping}
          />
        </ColumnWrapper>
      </GridWrapper>
      <ButtonContainer>
        <Tooltip2 position={'right'} title={t('mapping_button_save_tooltip')}>
          <Button onClick={() => saveChanges(true)}>
            {t('button_save_changes')}
          </Button>
        </Tooltip2>
      </ButtonContainer>

      <ModalWindow
        cancelText={t('button_back')}
        onCancel={() => setDeleteDialogVisible(false)}
        title={t('field_transformer_modal_title')}
        visible={isDeleteDialogVisible}
        t={t}
        okText={t('button_save_anyway')}
        onOk={() => saveChanges()}
      >
        <ModalText>
          <p>{t('field_transformer_modal_window')}</p>
          <p>{missingRequiredFields().join(', ')}</p>
        </ModalText>
      </ModalWindow>
    </Fragment>
  )
}

MappingContent.propTypes = {
  feedId: string,
  selectedOutgoing: outgoingShape,
}

export default MappingContent
