import styled from 'styled-components'
import { METAL, VERMILION } from '../../../constants'

export const HoverLinkDiv = styled.div`
  color: ${({ color }) => color ?? METAL};
  :hover {
    color: ${({ hoverColor }) => hoverColor ?? VERMILION};
    svg {
      color: ${({ hoverColor }) => hoverColor ?? VERMILION};
    }
  }
  svg {
    color: ${({ color }) => color ?? METAL};
  }
`
