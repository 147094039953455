import React, { Fragment } from 'react'
import styled from 'styled-components'
import { node, number, string, bool, object } from 'prop-types'

import { TEXT_BIG, SHADOW_GREY } from '../../constants'
import Text from './Text'

const ColumnWrapper = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 5px
  padding: 30px 20px;
  box-shadow: ${SHADOW_GREY};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const TitleText = styled.div`
  flex: 1;
`

const TitleSide = styled.div`
  flex: none;
`

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  margin: 20px 0;
`

const Title = styled.div`
  display: flex;
  align-items: flex-start;
`

const CardColumn = ({
  children,
  color,
  description,
  isPartner,
  numberShowed,
  textColor,
  title,
  totalNumber,
  link,
  side,
  t,
  hideType,
}) => {
  const columnType = isPartner ? t('partner') : t('master')

  return (
    <ColumnWrapper color={color}>
      <TitleWrapper>
        <TitleText>
          {title && (
            <Fragment>
              <Title>
                <Text size={TEXT_BIG} color={textColor} bold>
                  {title}
                </Text>
              </Title>
              <Text color={textColor}>
                {`${t('showing_data')} ${numberShowed} ${t(
                  'of'
                )} ${totalNumber} ${
                  !hideType ? `${columnType.toLowerCase()} ` : ''
                }${description.toLowerCase()}`}
              </Text>
            </Fragment>
          )}
          {!title && (
            <Text size={TEXT_BIG} bold>
              {t('select_partner')}
            </Text>
          )}
        </TitleText>
        {side && <TitleSide>{side}</TitleSide>}
      </TitleWrapper>
      <GridWrapper>{children}</GridWrapper>
    </ColumnWrapper>
  )
}

CardColumn.propTypes = {
  children: node.isRequired,
  color: string.isRequired,
  description: string.isRequired,
  isPartner: bool,
  numberShowed: number.isRequired,
  textColor: string,
  title: string,
  totalNumber: number.isRequired,
  link: string,
  side: object,
  hideType: bool,
}

export default CardColumn
