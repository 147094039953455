import React, { useEffect } from 'react'
import styled from 'styled-components'

import useAnnoucements from '../../hooks/Announcements'

import {
  DARK_GREY,
  WHITE,
  TEXT_SMALL,
  VERMILION,
  TEXT_MEDIUM,
} from '../../constants'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400

const NotificationBar = styled.div`
  padding: 4px;
  color: ${WHITE};
  width: 100%;
  font-family: 'Dm Sans';
  font-size: ${TEXT_MEDIUM};
  background-color: ${VERMILION};
  text-align: center;
`

const AnnouncementBar = () => {
  const { text, fetch } = useAnnoucements()

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return text && <NotificationBar>{text}</NotificationBar>
}

export default AnnouncementBar
