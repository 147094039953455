import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400
import {
  BLOCK_PADDING_REGULAR,
  METAL,
  SILVER,
  TEXT_BIG,
  TEXT_MEDIUM,
  VERMILION,
} from '../../../constants'

const Wrapper = styled.div`
  width: ${({ width }) => width || '140px'};
  ${({ padding }) =>
    padding ? `padding: ${padding};` : `padding: ${BLOCK_PADDING_REGULAR};`}
  margin-bottom:8px;
  border-bottom: 1px solid ${SILVER};
`
const WrapperNoBottom = styled.div`
  width: ${({ width }) => width || '140px'};
  ${({ padding }) =>
    padding ? `padding: ${padding};` : `padding: ${BLOCK_PADDING_REGULAR};`}
  margin-bottom:8px;
`
const Title = styled.div`
  text-align: center;
  font-size: ${TEXT_BIG};
  color: ${METAL};
  font-family: Dm Sans;
`

const Content = styled.div`
  font-size: ${TEXT_MEDIUM};
  text-align: center;
  color: ${VERMILION};
  font-family: Dm Sans;
`

const Square = ({ title, width, content, padding, bottomBorder }) => {
  return bottomBorder ? (
    <Wrapper padding={padding} width={width}>
      <Content>{content}</Content>
      <Title>{title}</Title>
    </Wrapper>
  ) : (
    <WrapperNoBottom padding={padding} width={width}>
      <Content>{content}</Content>
      <Title>{title}</Title>
    </WrapperNoBottom>
  )
}

Square.propTypes = {
  title: string,
  content: string,
  width: string,
  padding: string,
}

export default Square
