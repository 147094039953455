import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import {
  MIDDLE_GREY,
  WHITE,
  SHADOW_GREY,
  METAL,
  SILVER,
  VERMILION,
  PLATINUM,
} from '../constants'
import Button from '../components/shared/Button'
import Text from '../components/shared/Text'

const Container = styled.div`
  background-color: ${PLATINUM};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`

const LoginWindow = styled.div`
  background: ${WHITE};
  width: 80%;
  max-width: 500px;
  margin: 5% auto;
  border-radius: 20px;
  padding: 30px;
  box-shadow: ${SHADOW_GREY};
  text-align: center;
`

const LogInGoogleButton = styled(Button)`
  -moz-user-select: none;
  text-transform: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  &:hover {
    background-color: #dfe1e3;
  }
`
const GoogleLogo = styled.div`
  height: 20px;
  margin-right: 12px;
  min-width: 10px;
  width: 20px;
`
const ButtonContentWrapper = styled.div`
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`
const Spacer = styled.div`
  height: 20px;
`

const LoginPage = ({ loginUser, t }) => (
  <Container>
    <LoginWindow>
      <Text size="30px" color={METAL}>
        {t('login_title')}
      </Text>
      <Text size="25px" color={VERMILION}>
        {t('login_description')}
      </Text>
      <Spacer />
      <LogInGoogleButton
        data-id="button-google-login"
        type="submit"
        onClick={loginUser}
      >
        <div>
          <ButtonContentWrapper>
            <GoogleLogo>
              <svg
                viewBox="0 0 48 48"
                display="block"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ display: 'block' }}
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                />
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                />
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                />
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                />
                <path fill="none" d="M0 0h48v48H0z" />
              </svg>
            </GoogleLogo>
            <span>{t('login_google_button')}</span>
          </ButtonContentWrapper>
        </div>
      </LogInGoogleButton>
    </LoginWindow>
  </Container>
)

LoginPage.propTypes = {
  loginUser: func.isRequired,
}

export default LoginPage
