import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { string } from 'prop-types'
import { toast } from 'react-toastify'

import CardList from '../../shared/CardList'
import reducer, {
  initialState,
  getEnrichmentState,
  getIncomingEnrichments,
  getOutgoingEnrichments,
  ENRICHMENT_LIST_STATE,
} from './reducer'
import {
  fetchIncomingEnrichments,
  fetchOutgoingEnrichments,
  pauseEnrichment,
  changeEnrichmentPosition,
  removeEnrichment,
  updateEnrichment,
  createEnrichment,
} from './actions'
import { outgoingShape } from '../../../types'
import {
  LIGHT_GREY,
  METAL,
  MIDDLE_GREY,
  PLATINUM,
  SILVER,
  WHITE,
} from '../../../constants'

import FileUploadDialog from '../../shared/FileUploadDialog'
import RemoveModalWindow from '../../shared/RemoveModalWindow'
import PageDescription from '../../shared/PageDescription'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import ColumnGridWrapper from '../../shared/ColumnGridWrapper'

import useBrands from '../../shared/system/useBrands'

const EnrichmentList = ({ feedId, organisationId, selectedOutgoing, t }) => {
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const [isUploadDialogVisible, setUploadDialogVisible] = useState(false)
  const [selectedEnrichmentId, setselectedEnrichmentId] = useState(null)
  const [isOutgoing, setIsOutgoing] = useState(null)

  const [state, dispatch] = useReducer(reducer, initialState)

  const enrichmentListState = getEnrichmentState(state)
  const incomingEnrichments = getIncomingEnrichments(state)
  const outgoingEnrichments = getOutgoingEnrichments(state)

  const brands = useBrands()

  useEffect(
    () => {
      if (!selectedOutgoing) return
      fetchOutgoingEnrichments(dispatch, feedId, selectedOutgoing.id, t)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOutgoing, feedId]
  )

  useEffect(
    () => {
      fetchIncomingEnrichments(dispatch, feedId, null, t)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feedId]
  )

  // if (!selectedOutgoing) return

  // Shared part
  const reload = (sourceId, isOutgoing) =>
    (isOutgoing ? fetchOutgoingEnrichments : fetchIncomingEnrichments)(
      dispatch,
      feedId,
      sourceId,
      t
    )

  // Change card position //
  const onPositionChange = isOutgoing => ({
    id: enrichmentId,
    index: from,
    newPosition: to,
  }) => {
    if (from < 0 || to < 0) return
    const sourceId = isOutgoing && selectedOutgoing.id
    changeEnrichmentPosition(
      feedId,
      isOutgoing,
      enrichmentId,
      sourceId,
      to,
      t
    ).finally(() => reload(sourceId, isOutgoing))
  }

  // Deletion Part //
  const handleDeleteDialogVisibility = isOutgoing => (
    visible,
    enrichmentId
  ) => {
    setIsOutgoing(isOutgoing)
    setDeleteDialogVisible(visible)
    setselectedEnrichmentId(enrichmentId)
  }

  const removeEnrichmentFile = (isOutgoing, enrichmentId) => {
    const sourceId = isOutgoing && selectedOutgoing.id
    removeEnrichment(feedId, isOutgoing, sourceId, enrichmentId, t).finally(
      () => reload(sourceId, isOutgoing)
    )

    handleDeleteDialogVisibility(null)(false, null)
  }

  // Upload part
  const handleUploadDialogVisibility = isOutgoing => (
    visible,
    enrichmentId
  ) => {
    setIsOutgoing(isOutgoing)
    setUploadDialogVisible(visible)
    setselectedEnrichmentId(enrichmentId)
  }

  const handleUpload = isOutgoing => (name, data, file) => {
    const sourceId = isOutgoing && selectedOutgoing.id

    if (selectedEnrichmentId) {
      const enrichmentList = isOutgoing
        ? outgoingEnrichments
        : incomingEnrichments
      const selectedEnrichment = enrichmentList.find(
        ({ id }) => id === selectedEnrichmentId
      )

      updateEnrichment(
        feedId,
        isOutgoing,
        sourceId,
        selectedEnrichmentId,
        name,
        selectedEnrichment.fileId,
        data,
        t
      )
        .then(() => {
          toast.success(t('enrichments_update_successful'))
        })
        .catch(err => {
          toast.error(t('enrichments_update_unsuccessful'))
        })
        .finally(() => {
          reload(sourceId, isOutgoing)
        })
    } else {
      createEnrichment(
        feedId,
        isOutgoing,
        sourceId,
        name,
        organisationId,
        brands.selected.id,
        data,
        t
      )
        .then(() => {
          toast.success(t('enrichments_create_successful'))
        })
        .catch(err => {
          toast.error(t('enrichments_create_unsuccessful'))
        })
        .finally(() => reload(sourceId, isOutgoing))
    }

    handleUploadDialogVisibility(null)(false, null)
  }

  const handlePause = (isOutgoing, enrichments) => (id, enabled) => {
    const sourceId = isOutgoing && selectedOutgoing.id

    pauseEnrichment(
      feedId,
      isOutgoing,
      sourceId,
      id,
      enabled,
      dispatch,
      t,
      enrichments
    )
  }

  if (enrichmentListState === ENRICHMENT_LIST_STATE.FETCHING) return <Loader />

  return (
    <Fragment>
      <PageDescription
        description={t('enrichments_description')}
        heading={t('enrichments')}
      />
      <ColumnGridWrapper>
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_master_enrichment',
              tooltip: t('add_master_tooltip'),
              onClick: () => handleUploadDialogVisibility(false)(true, null),
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_partner_enrichment',
              tooltip: t('add_partner_tooltip'),
              onClick: () => handleUploadDialogVisibility(true)(true, null),
            },
          ]}
        />
        <CardList
          color={PLATINUM}
          description={t('enrichments_master')}
          handleUploadDialogVisibility={handleUploadDialogVisibility(false)}
          isEnrichment
          list={incomingEnrichments}
          name={t('master_feed')}
          onPositionChange={onPositionChange(false)}
          onPause={handlePause(false, incomingEnrichments)}
          onRemove={handleDeleteDialogVisibility(false)}
          t={t}
          textColor={METAL}
          cardHeight={'280px'}
          titleDescription={t('enrichments')}
          columnItemMargin={'0px'}
        />
        <CardList
          color={SILVER}
          description={t('enrichments_partner')}
          handleUploadDialogVisibility={handleUploadDialogVisibility(true)}
          isEnrichment
          isPartner
          list={outgoingEnrichments}
          cardHeight={'280px'}
          name={selectedOutgoing && selectedOutgoing.name}
          onPositionChange={onPositionChange(true)}
          onPause={handlePause(true, outgoingEnrichments)}
          onRemove={handleDeleteDialogVisibility(true)}
          partnerId={selectedOutgoing && selectedOutgoing.id}
          t={t}
          titleDescription={t('enrichments')}
          textColor={WHITE}
          columnItemMargin={'0px'}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_master_enrichment',
              tooltip: t('add_master_tooltip'),
              onClick: () => handleUploadDialogVisibility(false)(true, null),
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_partner_enrichment',
              tooltip: t('add_partner_tooltip'),
              onClick: () => handleUploadDialogVisibility(true)(true, null),
            },
          ]}
        />
      </ColumnGridWrapper>
      <FileUploadDialog
        onCancel={() => handleUploadDialogVisibility(null)(false, null)}
        onSave={(...args) => handleUpload(isOutgoing)(...args)}
        title={t('enrichments_new')}
        visible={isUploadDialogVisible}
        enableTypeSelection={false}
        type="override"
        t={t}
      />
      <RemoveModalWindow
        t={t}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility(isOutgoing)}
        onRemove={() => removeEnrichmentFile(isOutgoing, selectedEnrichmentId)}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

EnrichmentList.propTypes = {
  organisationId: string.isRequired,
  selectedOutgoing: outgoingShape,
  feedId: string.isRequired,
}

export default EnrichmentList
