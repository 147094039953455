import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { object, string, bool, func, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { recognizeText, recognizeColor } from '../../lib/recognizeCategory'
import { mappingShape, dropdownValueShape } from '../../types'
import {
  TEXT_MEDIUM,
  TEXT_SMALL,
  WHITE,
  SHADOW_GREY,
  SILVER,
  VERMILION,
  RED,
  METAL,
} from '../../constants'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import capitalizeFirstLetter from '../../lib/capitalizeFirstLetter'

import Button from './Button'
import Text from './Text'
import Dropdown from './Dropdown'
import ErrorBoundary from './ErrorBoundary'
import MappingEditable from './MappingEditable'

const InfoField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-right: 10px;
`

const Wrapper = styled.div`
  background: ${WHITE};
  border-color: ${({ isRequiredAndEmpty }) =>
    isRequiredAndEmpty ? RED : SILVER};
  height: 60px;
  padding: 5px 10px;
  box-shadow: ${SHADOW_GREY};
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid
    ${({ isRequiredAndEmpty }) => (isRequiredAndEmpty ? RED : SILVER)};
  border-top: 1px solid
    ${({ isRequiredAndEmpty }) => (isRequiredAndEmpty ? RED : 'transparent')};
  border-right: 1px solid
    ${({ isRequiredAndEmpty }) => (isRequiredAndEmpty ? RED : 'transparent')};
  border-left: 1px solid
    ${({ isRequiredAndEmpty }) => (isRequiredAndEmpty ? RED : 'transparent')};
`

const MappingStatus = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1px 3px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  ${({ isAddPartner }) => isAddPartner && 'width: 100px'};
  ${({ isAddPartner }) => isAddPartner && 'margin: 0 auto'};
`

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Preview = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-word;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  button {
    display: block;
    margin: 0 5px 5px;
  }
`

const Row = ({
  columnTypeKeys,
  dataId,
  deleteMapping,
  dispatch,
  exampleValue,
  isMaster,
  isAddPartner,
  isRequiredAndEmpty,
  keys,
  mapping,
  mappingRow,
  sourceColumn,
  updateMapping,
}) => {
  const [isRowEditable, setIsRowEditable] = useState(false)
  const { t } = useTranslation()

  const capitalizedKeys = keys.map(key => ({
    id: key.id,
    name: capitalizeFirstLetter(key.name),
  }))

  const nameField = isMaster
    ? sourceColumn.replace('master_', '')
    : sourceColumn

  const mappingColumnField = isMaster
    ? mappingRow.options.name || ''
    : capitalizeFirstLetter(
        mappingRow.options.name
          ? mappingRow.options.name.replace('master_', '')
          : ''
      )

  const columnType = recognizeText(mappingRow.system, t)

  return (
    <Fragment>
      {!isRowEditable && (
        <Wrapper isRequiredAndEmpty={isRequiredAndEmpty} data-id={dataId}>
          <ErrorBoundary>
            <InfoField>
              <div>
                <Text size={TEXT_MEDIUM} bold color={METAL}>
                  {capitalizeFirstLetter(nameField)}
                </Text>
                {!isAddPartner && (
                  <MappingStatus
                    backgroundColor={recognizeColor(mappingRow.system)}
                  >
                    <Text size={TEXT_SMALL} color={WHITE}>
                      {recognizeText(mappingRow.system, t)}
                    </Text>
                  </MappingStatus>
                )}
              </div>
            </InfoField>

            <DropdownWrapper
              data-id={`mapping-value-${mappingRow.options.name}`}
            >
              <Dropdown
                onChange={item =>
                  updateMapping(sourceColumn, item.id, mapping, dispatch)
                }
                options={[{ id: '', name: t('none') }, ...capitalizedKeys]}
                dataId={`mapping-value-${sourceColumn}`}
                placeholder={t('none')}
                placeholderColor={VERMILION}
                value={{
                  id: mappingRow.options.name || '',
                  name: mappingColumnField,
                }}
              />
            </DropdownWrapper>
            {isMaster && (
              <Preview>
                <Text
                  dataId={`previw-${exampleValue}`}
                  size={TEXT_SMALL}
                  color={METAL}
                >
                  {exampleValue}
                </Text>
              </Preview>
            )}
            {isAddPartner && (
              <Fragment>
                <InfoField>
                  <MappingStatus
                    isAddPartner
                    backgroundColor={recognizeColor(mappingRow.system)}
                  >
                    <Text
                      dataId={`mapping-row-${recognizeText(
                        mappingRow.system,
                        t
                      )}`}
                      size={TEXT_SMALL}
                      color={WHITE}
                    >
                      {recognizeText(mappingRow.system, t)}
                    </Text>
                  </MappingStatus>
                </InfoField>
                <ButtonsContainer>
                  <Button
                    theme={SECONDARY_BUTTON}
                    onClick={() => setIsRowEditable(!isRowEditable)}
                    dataId={`edit-${sourceColumn}`}
                  >
                    {t('button_edit')}
                  </Button>
                  <Button
                    theme={SECONDARY_BUTTON}
                    onClick={() =>
                      deleteMapping(dispatch, mapping, sourceColumn)
                    }
                    dataId={`delete-${sourceColumn}`}
                  >
                    {t('button_delete')}
                  </Button>
                </ButtonsContainer>
              </Fragment>
            )}
          </ErrorBoundary>
        </Wrapper>
      )}
      {isRowEditable && (
        <MappingEditable
          columnType={columnType}
          columnTypeKeys={columnTypeKeys}
          dispatch={dispatch}
          isAddPartner={isAddPartner}
          keys={keys}
          mapping={mapping}
          mappingField={mappingColumnField}
          name={nameField}
          setFormVisible={setIsRowEditable}
          t={t}
          updateMapping={updateMapping}
        />
      )}
    </Fragment>
  )
}

Row.propTypes = {
  columnTypeKeys: arrayOf(dropdownValueShape),
  dataId: string,
  deleteMapping: func,
  exampleValue: string,
  isAddPartner: bool,
  isMaster: bool,
  isRequiredAndEmpty: bool,
  keys: arrayOf(dropdownValueShape).isRequired,
  mapping: object.isRequired,
  mappingRow: mappingShape.isRequired,
  sourceColumn: string.isRequired,
  updateMapping: func.isRequired,
}

export default Row
