import React, { Fragment } from 'react'
import { arrayOf, string, object } from 'prop-types'

import downloadFile from '../../../lib/downloadFile'
import { outgoingShape as incomingShape, templateShape } from '../../../types'
import { APP_STATE, CARD_HEIGHT_LARGE, TEXT_BIG } from '../../../constants'
import { imagesUrl } from '../../../constants/urls'

import Card from '../../shared/Card'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import ContentWrapper from '../../shared/ContentWrapper'
import CardGridWrapper from '../../shared/CardGridWrapper'
import Text from '../../shared/Text'
import styled from 'styled-components'

const Spacer = styled.div`
  padding-top: 10px;
`

const IncomingCards = ({
  feedId,
  // onPauseAll,
  incomingListState,
  incoming,
  templates,
  organisationId,
  notifications,
  t,
}) => {
  const imageDictionary = (templates || []).reduce((acc, templ) => {
    acc[templ.id] = `${imagesUrl}${templ.imageUrl}`
    return acc
  }, {})

  return (
    <Fragment>
      <PageDescription
        heading={t('incoming')}
        description={t('incoming_desc')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            'data-id': 'new-incoming',
            name: 'new_incoming',
            tooltip: t('incoming_button_new_outgoing_tooltip'),
            link: `/${organisationId}/feeds/${feedId}/incoming/new`,
          },
        ]}
      />
      <Spacer />
      {incomingListState === APP_STATE.DONE && (
        <ContentWrapper>
          <CardGridWrapper>
            {incoming.length === 0 && (
              <Text size={TEXT_BIG}>{t('no_incoming_available')}</Text>
            )}
            {incoming.map(
              ({ id, detailUrl, name, job, enabled, publish, partnerId }) => (
                <Card
                  alertLink={`/${organisationId}/reports`}
                  alertCount={
                    notifications &&
                    notifications.filter(x => x.data.outgoingId === id).length
                  }
                  key={id}
                  height={CARD_HEIGHT_LARGE}
                  img={imageDictionary[partnerId]}
                  title={name}
                  column={[
                    {
                      heading: t('number_of_products'),
                      description:
                        job && job.stats && job.stats.totalProductCount > 0
                          ? t('numericValueInput', {
                              value: job.stats.totalProductCount,
                            })
                          : t('not_available'),
                    },
                    {
                      heading: t('incoming_last_published'),
                      tooltip: t('incoming_last_published_tooltip'),
                      description:
                        job && job.stats
                          ? t('dateValueInput', {
                              value: new Date(job.stats.finishedAt),
                            })
                          : t('not_available'),
                    },
                  ]}
                  buttons={[
                    {
                      name: t('button_edit'),
                      tooltip: t('incoming_button_edit_tooltip'),
                      link: detailUrl,
                    },
                    {
                      name: t('button_download_from_source'),
                      tooltip: t('incoming_button_from_source_tooltip'),
                      onClick: () =>
                        downloadFile(
                          t,
                          `/jobs/${job.id}/incoming/${id}/data`,
                          `${name}.csv`
                        ),
                      disabled: !job.id,
                    },
                  ]}
                />
              )
            )}
          </CardGridWrapper>
        </ContentWrapper>
      )}
    </Fragment>
  )
}

IncomingCards.propTypes = {
  feedId: string.isRequired,
  // onPauseAll: func,
  incomingListState: string,
  notifications: arrayOf(object),
  incoming: arrayOf(incomingShape).isRequired,
  organisationId: string.isRequired,
  templates: arrayOf(templateShape),
}

export default IncomingCards
