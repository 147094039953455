import React, { useEffect, useReducer, useState, Fragment } from 'react'
import styled from 'styled-components'
import { arrayOf, string, func, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from '../Button'
import Text from '../Text'
import PageDescription from '../PageDescription'

import { initialState, reducer } from './reducer'
import { fetchTemplates } from './actions'

import {
  LIGHT_GREY,
  DARK_GREY,
  TEXT_SMALL,
  VERMILION,
} from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'
import { ruleShape } from '../../../types'

const CopiedContainer = styled.div`
  padding: 10px;
`

const CopiedRule = styled.div`
  border: 1px solid ${LIGHT_GREY};
  margin: 10px 0;
  padding: 10px 25px 10px 10px;
  position: relative;
`

const StyledButton = styled(Button)`
  margin-right: 5px;
`

const CloseButton = styled.a`
  position: absolute;
  right: 10px;
  top: 8px;
  width: 16px;
  height: 16px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: ${DARK_GREY};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const NavWrapper = styled.div`
  display: flex;
`

const NavLink = styled.a`
  margin: 4px;
  flex: 1;
  text-align: center;
  color: ${DARK_GREY};
  box-sizing: border-box;
  height: 25px;
  border-bottom: ${({ selected }) => selected && ` 2px solid ${VERMILION}`};
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  outline: none;

  :hover {
    outline: none;
    text-decoration: none;
    color: ${DARK_GREY};
    border-bottom: ${({ selected }) => !selected && ` 2px solid ${VERMILION}`};
  }
`

const Clipboard = ({ buttons, items, deleteItem, type }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [selected, setSelected] = useState(0)
  const { t } = useTranslation()

  const { templates } = state

  useEffect(
    () => {
      fetchTemplates(type, dispatch)
    },
    [type]
  )

  return (
    <CopiedContainer data-id="clipboard-container">
      <NavWrapper>
        <NavLink selected={selected === 0} onClick={() => setSelected(0)}>
          {t('clipboard_user')}
        </NavLink>
        <NavLink selected={selected === 1} onClick={() => setSelected(1)}>
          {t('clipboard_default')}
        </NavLink>
      </NavWrapper>

      {selected === 0 && (
        <Fragment>
          {items.length === 0 && (
            <PageDescription
              heading={t('clipboard-no-items')}
              description={t('clipboard-no-items-description')}
            />
          )}
          {items.map((item, index) => (
            <CopiedRule key={item.name} data-id={`block-${item.name}`}>
              <Text bold>{item.name}</Text>
              <CloseButton
                data-id={`close-button-${item.name}`}
                onClick={() => deleteItem(index)}
              />
              {buttons.map((button, i) => (
                <StyledButton
                  data-id={`button-${button.name}`}
                  theme={SECONDARY_BUTTON}
                  fontSize={TEXT_SMALL}
                  key={`button-${i}`}
                  onClick={() => button.onClick(item)}
                >
                  {button.name}
                </StyledButton>
              ))}
            </CopiedRule>
          ))}
        </Fragment>
      )}

      {selected === 1 && (
        <Fragment>
          {templates &&
            templates.map(item => (
              <CopiedRule key={item.name}>
                <Text bold>{t(item.name)}</Text>
                {buttons.map((button, i) => (
                  <StyledButton
                    theme={SECONDARY_BUTTON}
                    fontSize={TEXT_SMALL}
                    key={`button-${i}-template`}
                    onClick={() =>
                      button.onClick({ ...item, name: t(item.name) })
                    }
                  >
                    {button.name}
                  </StyledButton>
                ))}
              </CopiedRule>
            ))}
        </Fragment>
      )}
    </CopiedContainer>
  )
}

Clipboard.propTypes = {
  buttons: arrayOf(shape({ name: string, onClick: func })).isRequired,
  deleteItem: func.isRequired,
  items: arrayOf(ruleShape),
  type: string.isRequired,
}

export default Clipboard
