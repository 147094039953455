import React, { Fragment, useEffect, useReducer, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import Input from '../../shared/Input'
import Button from '../../shared/Button'
import Form from '../../shared/Form'
import Text from '../../shared/Text'
import EditableRow from '../../shared/EditableRow'
import { WHITE, LIGHT_BLACK } from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

import { fetchBrands, editBrand, createBrand } from './actions'
import { initialState, reducer } from './reducer'

const Row = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 10px;
`
const Spacer = styled.div`
  padding-top: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 10px;
  }
`

const FormRow = styled(Row)`
  background: ${WHITE};
`

const BrandList = ({ t, organisationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isFormVisible, setFormVisible] = useState(false)
  const { brandList, brandListState } = state

  useEffect(
    () => {
      fetchBrands({ organisationId, dispatch, t })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId]
  )

  if (!brandListState || brandListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('brands')}
        description={t('brands_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('add_new_brand'),
            onClick: () => setFormVisible(true),
          },
        ]}
      />
      <Spacer />
      <ContentWrapper>
        <Row>
          <Text bold color={LIGHT_BLACK}>
            {t('table_header1')}
          </Text>
          <Text bold color={LIGHT_BLACK}>
            {t('table_header2')}
          </Text>
          <Text> </Text>
        </Row>
        {brandList.map(brand => (
          <EditableRow
            dispatch={dispatch}
            editRow={editBrand}
            fetchRows={fetchBrands}
            hasImageLink
            key={brand.id}
            organisationId={organisationId}
            row={brand}
            t={t}
          />
        ))}
        {isFormVisible && (
          <Form
            initialValues={{
              name: '',
              imageLink: '',
            }}
            onSubmit={({ name, imageLink }) => {
              const newBrand = { name, imageLink }
              createBrand(organisationId, newBrand, dispatch, t)
              setFormVisible(false)
            }}
            content={() => (
              <FormRow>
                <Input
                  name="name"
                  data-id="new-brand-name"
                  placeholder={t('input_new_brand_name')}
                />
                <Input
                  name="imageLink"
                  data-id="new-brand-imageLink"
                  placeholder={t('input_new_brand_imageLink')}
                />
                <ButtonsContainer>
                  <Button
                    theme={SECONDARY_BUTTON}
                    onClick={() => setFormVisible(false)}
                  >
                    {t('button_cancel')}
                  </Button>
                  <Button type="submit">{t('button_save_changes')}</Button>
                </ButtonsContainer>
              </FormRow>
            )}
          />
        )}
      </ContentWrapper>
    </Fragment>
  )
}

BrandList.propTypes = {
  organisationId: string.isRequired,
}

export default BrandList
