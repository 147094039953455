import { VERMILION, WHITE, RED, METAL } from './'

export const PRIMARY_BUTTON = {
  backgroundColor: VERMILION,
  textColor: WHITE,
}

export const SECONDARY_BUTTON = {
  backgroundColor: 'transparent',
  textColor: VERMILION,
  backgroundColorHover: VERMILION,
}

export const TRANSPARENT_BUTTON = {
  backgroundColor: 'transparent',
  textColor: METAL,
}

export const RED_BUTTON = {
  backgroundColor: RED,
  textColor: WHITE,
  backgroundColorHover: RED,
}

export const LINK_BUTTON_SMALL = {
  backgroundColor: 'transparent',
  textColor: METAL,
  textColorHover: VERMILION,
  backgroundColorHover: 'transparent',
}

export const LINK_BUTTON_SMALL_INVERTED = {
  backgroundColor: 'transparent',
  textColor: WHITE,
  textColorHover: METAL,
  backgroundColorHover: 'transparent',
}
