import styled from 'styled-components'
import { MAX_CONTENT_WIDTH, LIGHT_GREY, SHADOW_GREY } from '../../constants'

const ContentWrapper = styled.div`
  background: ${LIGHT_GREY};
  padding: 20px 20px;
  max-width: ${MAX_CONTENT_WIDTH};
  border-radius: 5px;
  box-shadow: ${SHADOW_GREY};
`

export default ContentWrapper
