import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { toast } from 'react-toastify'
import { Link as RouterLink } from '@reach/router'
import PageDescription from '../components/shared/PageDescription'
import Button from '../components/shared/Button'
import Input from '../components/shared/Input'
import Form from '../components/shared/Form'
import Dropdown from '../components/shared/Dropdown'
import api from '../lib/api'
import { timezoneWithUTC } from '../lib/getTimezoneWithUtc'
import { timezones } from '../constants/timezones'
import { languages } from '../constants/languages'
import { countryFormats } from '../constants/countryFormats'
import { navigate } from '@reach/router'
import { SECONDARY_BUTTON } from '../constants/buttonThemes'
import { userShape } from '../types'
import { userSettingSchema } from '../formValidationSchemas'
import { HoverLinkDiv } from '../components/shared/HoverLinkDiv'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400
import useOrganisations from '../components/shared/system/useOrganisations'
import { useTranslation } from 'react-i18next'
import Text from '../components/shared/Text'
import { FaAngleRight } from 'react-icons/fa'
import {
  PLATINUM,
  SHADOW_GREY,
  TEXT_MEDIUM,
  VERMILION,
  WHITE,
} from '../constants'

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  grid-gap: 20px;
  font-family: 'Dm Sans';
  font-size: ${TEXT_MEDIUM};
`

const PermissionWrapper = styled.div`
  background:${VERMILION};
  height:30px;
  padding-bottom:20px;
  padding-top: 5px;
  padding-left: 5px;
  border-top-right-radius:5px
  border-top-left-radius:5px
  width: 500px;
  box-shadow: ${SHADOW_GREY};
`
const ListWrapper = styled.div`
  background: ${WHITE};
  width: 500px;
  padding-bottom: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: ${SHADOW_GREY};
`
const FontLimit = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 480px;
  display: inline-block;
`
const TextWrapper = styled.div`
  padding-left: 5px;
  border-top: 1px solid ${PLATINUM};
  display: flex;
  margin: auto;
  justify-content: space-between;
`

const AvatarImg = styled.img`
  width: 50px;
  height: 50px;
  display: block;
  float:left
  text-align: left;
  border-style: solid;
  border-width: 1px;
  border-color: ${PLATINUM};
  border-radius: 5px;
`

const UserSettingPage = ({
  dispatch,
  fetchUser,
  user: {
    id,
    name,
    email,
    avatarUrl,
    updatedAt,
    settings: { language, format, timezone },
  },
}) => {
  const { selected, setSelected, list: organisations } = useOrganisations()
  const { t } = useTranslation()

  const organisationId = selected.id

  const timezonesWithUTC = timezones.map(timezone => timezoneWithUTC(timezone))
  const [userLanguage, setUserLanguage] = useState(language)
  const [userAvatarUrl, setUserAvatarUrl] = useState(avatarUrl)
  const [userTimezone, setUserTimezone] = useState(timezone)
  const [userFormat, setUserFormat] = useState(format)
  const TOAST_SUCCESS_ID = 'TOAST_SUCCESS_ID'
  const TOAST_ERROR_ID = 'TOAST_ERROR_ID'

  useEffect(
    () => {
      fetchUser(dispatch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, userAvatarUrl, userLanguage, userFormat, userTimezone]
  )

  const saveData = ({ avatarUrl, format, language, name, timezone }) => {
    const updatedUser = {
      avatarUrl: avatarUrl,
      name: name,
      settings: {
        language,
        timezone,
        format,
      },
    }

    api
      .patch(`/users/${id}`, updatedUser)
      .then(
        ({
          data: {
            settings: { language, timezone, format },
          },
          avatarUrl,
        }) => {
          setUserLanguage(language)
          setUserAvatarUrl(avatarUrl)
          setUserTimezone(timezone)
          setUserFormat(format)

          if (!toast.isActive(TOAST_SUCCESS_ID)) {
            toast.success(t('user_setting_user_saved_successful'), {
              toastId: TOAST_SUCCESS_ID,
            })
          }
        }
      )
      .catch(err => {
        if (!toast.isActive(TOAST_ERROR_ID)) {
          toast.error(t('user_setting_user_saved_unsuccessful'), {
            toastId: TOAST_ERROR_ID,
          })
        }

        console.log(err)
      })
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('user_setting_heading')}
        description={t('user_setting_description')}
      />
      <Form
        initialValues={{
          name: name,
          language: language,
          timezone: timezone,
          avatarUrl: avatarUrl,
          format: format,
        }}
        onSubmit={values => saveData(values)}
        validationSchema={userSettingSchema}
        content={() => (
          <Fragment>
            <GridWrapper>
              <FormWrapper>
                <Text bold color={VERMILION}>
                  {t('email')}:
                </Text>
                <Text>{email}</Text>
                <Text bold color={VERMILION}>
                  {t('name')}:
                </Text>
                <Input name="name" data-id="input-user-setting-name" />
                <Text bold color={VERMILION}>
                  {t('User_Icon')}:
                </Text>
                <AvatarImg
                  data-id="user_avatar"
                  src={avatarUrl}
                  alt="user avatar"
                />
                {/* <Text bold color={VERMILION}> //? Re comment and un-bracket if you need to test with different image sources
                  {'Image Source:'}
                </Text>
                <Input
                  name="avatarUrl"
                  data-id="input-user-setting-avatar"
                  maximumLength="1024"
                /> */}
                <Text bold color={VERMILION}>
                  {t('user_setting_logged')}
                </Text>
                <Text data-id="last-time-logged">
                  {t('dateValueInput', { value: new Date(updatedAt) })}
                </Text>
                <Text bold color={VERMILION}>
                  {t('language')}:
                </Text>
                <Dropdown
                  dataId="dropdown-user-setting-language"
                  name="language"
                  options={languages}
                />
                <Text bold color={VERMILION}>
                  {t('user_setting_country_label')}:
                </Text>
                <Dropdown
                  dataId="dropdown-user-setting-country"
                  name="format"
                  options={countryFormats}
                />
                <Text bold color={VERMILION}>
                  {t('user_setting_timezone_label')}:
                </Text>
                <Dropdown
                  name="timezone"
                  optionValueKey="id"
                  dataId="dropdown-user-setting-timezone"
                  options={timezonesWithUTC}
                />
              </FormWrapper>
              <FormWrapper>
                <div />
                <div data-id="user_orgs">
                  <PermissionWrapper>
                    <Text bold color={WHITE}>
                      {t('user_setting_permission')}:
                    </Text>
                  </PermissionWrapper>
                  <ListWrapper>
                    {organisations.map(organisation => (
                      <TextWrapper>
                        <RouterLink
                          onClick={() => setSelected(organisation)}
                          to={`../${organisation.id}/feeds`}
                        >
                          <HoverLinkDiv>
                            <FontLimit>
                              <span key={organisation.id}>
                                {organisation.name}
                              </span>
                            </FontLimit>
                            <FaAngleRight />
                          </HoverLinkDiv>
                        </RouterLink>
                      </TextWrapper>
                    ))}
                  </ListWrapper>
                </div>
              </FormWrapper>
            </GridWrapper>
            <ButtonWrapper>
              <StyledButton
                onClick={() => navigate(`${organisationId}/feeds`)}
                theme={SECONDARY_BUTTON}
              >
                {t('button_cancel')}
              </StyledButton>
              <Button data-id="button-feed-save" type="submit">
                {t('button_save_settings')}
              </Button>
            </ButtonWrapper>
          </Fragment>
        )}
      />
    </Fragment>
  )
}

UserSettingPage.propTypes = {
  fetchUser: func.isRequired,
  user: userShape.isRequired,
}

export default UserSettingPage
