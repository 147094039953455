import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import Cron from 'cron-converter'
import { func, string } from 'prop-types'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import getCronValue from '../../lib/getCronValue'
import { HOURS, WHITE, TEXT_BIG, VERMILION, METAL } from '../../constants'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import { timezones } from '../../constants/timezones'
import { getDiffFromUTC, timezoneWithUTC } from '../../lib/getTimezoneWithUtc'
import { triggerShape } from '../../types'
import { receiveFeed } from '../App/actions'
import { getCurrentTimezone } from '../../i18n'

import Button from '../shared/Button'
import Tooltip from '../shared/Tooltip2'
import TooltipIcon from '../shared/TooltipIcon'

import Dropdown from '../shared/Dropdown'
import Text from '../shared/Text'
import PageDescription from '../shared/PageDescription'
import FormWrapper from '../shared/FormWrapper'

const Spacer = styled.div`
  padding-top: 10px;
`

const ScheduleWrapper = styled.div`
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: center;
`

const HourList = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin: 40px 0;
`

const BottomButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Timezone = styled.div`
  display: grid;
  grid-template-columns: max-content 40px 350px;
  align-items: center;
`

const HourItem = styled.div`
  padding: 0;
  text-align: center;
  cursor: pointer;
  background: ${({ selected }) => selected && VERMILION};
  border-top-left-radius: ${({ prevSelected }) => !prevSelected && '5px'};
  border-bottom-left-radius: ${({ prevSelected }) => !prevSelected && '5px'};
  border-top-right-radius: ${({ nextSelected }) => !nextSelected && '5px'};
  border-bottom-right-radius: ${({ nextSelected }) => !nextSelected && '5px'};
  transition: all 0.2s linear;
`

const defaultTrigger = {
  type: 'cron',
  options: {
    cron: '',
  },
}

const Scheduling = ({
  dispatch,
  feedId,
  organisationId,
  saveFeed,
  trigger,
  t,
}) => {
  let userTimezone =
    timezones.find(timezone => timezone.id === getCurrentTimezone()) ||
    timezones[6]

  const currentTrigger = trigger || defaultTrigger
  const [selectedHours, setSelectedHours] = useState([])
  const [currentTimezone, setCurrentTimezone] = useState(userTimezone)

  useEffect(
    () => {
      const { cron } = currentTrigger.options

      if (cron) {
        const cronInstance = new Cron().fromString(cron)
        const cronArray = cronInstance.toArray()
        const setHoursByUser = cronArray[1].map(hour => {
          const displayedHour = hour - getDiffFromUTC(currentTimezone)
          return calcPrevNextDayHour(displayedHour)
        })

        setSelectedHours(setHoursByUser)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTrigger]
  )

  const handleChangeTimezone = value => {
    const hoursChangedTimezone = selectedHours.map(hour => {
      const displayedHour =
        hour + getDiffFromUTC(currentTimezone) - getDiffFromUTC(value)
      return calcPrevNextDayHour(displayedHour)
    })
    setCurrentTimezone(value)
    setSelectedHours(hoursChangedTimezone)
  }

  const timezonesWithUTC = timezones.map(timezone => timezoneWithUTC(timezone))

  const calcPrevNextDayHour = displayedHour => {
    if (displayedHour >= 24) {
      return displayedHour - 24
    } else if (displayedHour < 0) {
      return displayedHour + 24
    } else return displayedHour
  }

  const handleChange = newHour => {
    if (isHourSelected(newHour)) {
      const newHours = selectedHours.filter(hour => hour !== newHour)

      setSelectedHours(newHours)
    } else {
      setSelectedHours([newHour, ...selectedHours])
    }
  }

  const clearAll = () => {
    setSelectedHours([])
  }

  const selectAll = () => {
    const hourValues = HOURS.map(({ id }) => id)

    setSelectedHours(hourValues)
  }

  const saveSchedule = () => {
    const selectedHoursUTC = selectedHours.map(hour => {
      const displayedHour = hour + getDiffFromUTC(currentTimezone)
      return calcPrevNextDayHour(displayedHour)
    })

    let trigger = null
    if (selectedHoursUTC.length) {
      const newCron = getCronValue({ hours: selectedHoursUTC })

      trigger = {
        ...currentTrigger,
        options: {
          ...currentTrigger.options,
          cron: newCron,
        },
      }
    }

    saveFeed(
      feedId,
      {
        trigger,
      },
      receiveFeed,
      dispatch,
      t
    )
      .then(() => toast.success(t('scheduling_save_success')))
      .catch(() => toast.error(t('scheduling_save_error')))

    navigate(`/${organisationId}/feeds/${feedId}/scheduling`, { replace: true })
  }

  const isHourSelected = index => {
    const hour = HOURS[index]

    return hour && selectedHours.includes(hour.id)
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('scheduling')}
        description={t('scheduling_description')}
      />
      <Tooltip title={t('scheduling_clear_tooltip')}>
        <Button
          data-id="button-clear-all"
          onClick={clearAll}
          theme={SECONDARY_BUTTON}
        >
          {t('button_clear_one')}
        </Button>
      </Tooltip>
      <Tooltip title={t('scheduling_select_all_tooltip')}>
        <Button
          data-id="button-select-all"
          onClick={selectAll}
          theme={SECONDARY_BUTTON}
        >
          {t('button_select')}
        </Button>
      </Tooltip>
      <Spacer />
      <FormWrapper>
        <Timezone>
          <Text size={TEXT_BIG}>{t('time_zone')}:</Text>
          <TooltipIcon title={t('file_upload_name_tooltip')} position={'top'} />
          <Dropdown
            optionValueKey="id"
            dataId="dropdown-user-setting-timezone"
            options={timezonesWithUTC}
            value={timezoneWithUTC(currentTimezone)}
            onChange={value => handleChangeTimezone(value)}
          />
        </Timezone>

        <ScheduleWrapper>
          <Text color={METAL} bold>
            {t('scheduling_all_days')}
          </Text>
          <HourList>
            {HOURS.map(({ id, name }, index) => {
              const selected = isHourSelected(id)
              const prevSelected = isHourSelected(index - 1)
              const nextSelected = isHourSelected(index + 1)

              return (
                <HourItem
                  key={id}
                  prevSelected={prevSelected}
                  nextSelected={nextSelected}
                  selected={selected}
                  onClick={() => handleChange(id)}
                >
                  <Text
                    dataId={`div-hour-${name}`}
                    color={selected ? WHITE : METAL}
                  >
                    {name}
                  </Text>
                </HourItem>
              )
            })}
          </HourList>
        </ScheduleWrapper>

        <BottomButtonWrapper>
          <Button data-id={'button-scheduler-save'} onClick={saveSchedule}>
            {t('button_save')}
          </Button>
        </BottomButtonWrapper>
      </FormWrapper>
    </Fragment>
  )
}

Scheduling.propTypes = {
  dispatch: func.isRequired,
  feedId: string.isRequired,
  organisationId: string.isRequired,
  trigger: triggerShape,
  saveFeed: func.isRequired,
}

export default Scheduling
