import React from 'react'
import { string } from 'prop-types'
import { METAL, VERMILION } from '../../../../constants'

const FaTypeConversionList = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill={color}
  >
    <g
      id="Conversion_List_Icon"
      data-name="Conversion List Icon"
      transform="translate(-1245 -235)"
    >
      <g
        id="Rectangle_1683"
        data-name="Rectangle 1683"
        transform="translate(1245 235)"
        fill="none"
        stroke={METAL}
        strokeWidth="2"
      >
        <rect width="39" height="39" rx="5" stroke="none" />
        <rect x="1" y="1" width="37" height="37" rx="4" fill="none" />
      </g>
      <g id="Group_1191" data-name="Group 1191" transform="translate(0.5)">
        <g
          id="Group_1189"
          data-name="Group 1189"
          transform="translate(-52 1.5)"
        >
          <line
            id="Line_167"
            data-name="Line 167"
            x2="8"
            transform="translate(1304.5 244.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_168"
            data-name="Line 168"
            x2="8"
            transform="translate(1304.5 250.5)"
            fill="none"
            stroke={VERMILION}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_169"
            data-name="Line 169"
            x2="8"
            transform="translate(1304.5 256.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_170"
            data-name="Line 170"
            x2="8"
            transform="translate(1304.5 262.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
        <g
          id="Group_1190"
          data-name="Group 1190"
          transform="translate(-37 1.5)"
        >
          <line
            id="Line_167-2"
            data-name="Line 167"
            x2="8"
            transform="translate(1304.5 244.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_168-2"
            data-name="Line 168"
            x2="8"
            transform="translate(1304.5 250.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_169-2"
            data-name="Line 169"
            x2="8"
            transform="translate(1304.5 256.5)"
            fill="none"
            stroke={VERMILION}
            strokeLinecap="round"
            strokeWidth="2"
          />
          <line
            id="Line_170-2"
            data-name="Line 170"
            x2="8"
            transform="translate(1304.5 262.5)"
            fill="none"
            stroke={METAL}
            strokeLinecap="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
)

FaTypeConversionList.propTypes = {
  color: string,
}

export default FaTypeConversionList
