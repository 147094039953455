import styled, { keyframes } from 'styled-components'
import { ORANGE, VERMILION } from '../../constants'

const spinAround = keyframes`
  from {
    transform-origin: 0 0;
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform-origin: 0 0;
    transform: rotate(360deg) translate(-50%, -50%);
  }
`

const Loader = styled.div`
  position: relative;
  min-height: 5rem;
  margin: 2rem 0;
  height: 100%;

  &:after {
    animation: ${spinAround} 1000ms infinite linear;
    border: solid ${VERMILION};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    position: absolute;
    height: 1em;
    width: 1em;
    font-size: 4rem;
    left: 50%;
    top: 50%;
  }
`

export default Loader
