import React, { Fragment } from 'react'
import { string, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import useBrands from '../../shared/system/useBrands'
import {
  TEXT_MEDIUM,
  BUTTON_PADDING_SMALL,
  TEXT_SMALL,
  METAL,
  TEXT_BIG,
  VERMILION,
  ICON_SIZE_SMALL,
} from '../../../constants'
import { LINK_BUTTON_SMALL } from '../../../constants/buttonThemes'
import { FaAngleRight, FaEdit } from 'react-icons/fa'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400
import Block from '../../shared/Block'
import BlockRow from '../../shared/Block/Row'
import BlockSquare from '../../shared/Block/Square'
import Button from '../../shared/Button'
import EditRow from '../../shared/Block/EditRow'
import PageDescription from '../../shared/PageDescription'
import Text from '../../shared/Text'
import Link from '../../shared/Link'
import { HoverLinkDiv } from '../../shared/HoverLinkDiv'
import { setOutgoingId, setIncomingId } from '../../../components/App'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Separator = styled.div`
  margin: 0 -12px;
  border-bottom: 1px solid ${METAL};
  margin-bottom: 8px;
`

const InfoWrapper = styled(Wrapper)`
  align-items: flex-end;
`
const FontRow = styled.div`
  font-family: 'Dm Sans';
  font-size: ${({ fontSize }) => fontSize};
  vertical-align: 'Center';
`

const FontLimit = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const feedJobDurationToString = job => {
  const total = (new Date(job.finishedAt) - new Date(job.startedAt)) / 1000
  const minutes = Math.round(total / 60)
  const seconds = Math.round(total % 60)

  const minutesOutput = minutes && `${minutes}m`
  const secondsOutput = seconds && `${seconds}s`
  return [minutesOutput, secondsOutput].filter(x => x).join(' ')
}

const Overview = ({ feed, organisationId, feedId }) => {
  const { t } = useTranslation()
  const brands = useBrands()

  const CreateEditRow = isOutgoing => item => (
    <div
      onClick={
        isOutgoing
          ? () => {
              setOutgoingId(item.id)
            }
          : () => {
              setIncomingId(item.id)
            }
      }
    >
      <Link
        link={isOutgoing ? `../outgoing/${item.id}` : `../incoming/${item.id}`}
      >
        <HoverLinkDiv>
          <FontRow fontSize={TEXT_MEDIUM}>
            <BlockRow padding="0 0 0 8px" key={item.id}>
              <EditRow>
                <FontLimit>
                  <span>{item.name}</span>
                </FontLimit>
                <FaAngleRight />
              </EditRow>
            </BlockRow>
          </FontRow>
        </HoverLinkDiv>
      </Link>
    </div>
  )

  return (
    //? Todo change into a react function. This massive const is hard to work with and understand this should be split up.
    <Fragment>
      <PageDescription
        heading={t('overview')}
        description={t('overview_description')}
      />
      <Wrapper>
        {feed.notifications && feed.notifications.length > 0 && (
          <Block title={'Notifications'} width="80%" padding="0">
            {feed.notifications.map((n, i) => (
              <BlockRow padding="12px" border="bottom" key={i}>
                <EditRow>
                  <span>
                    {t(n.type)}
                    {/* {n.data} */}
                  </span>
                  <Button
                    theme={LINK_BUTTON_SMALL}
                    fontSize={TEXT_SMALL}
                    padding={BUTTON_PADDING_SMALL}
                    link={'../../../reports'}
                  >
                    {t('goto')}
                  </Button>
                </EditRow>
              </BlockRow>
            ))}
          </Block>
        )}

        <Block
          title={t('basic_info')}
          link={'../basic-info'}
          linkText={t('button_edit')}
          width="370px"
          IconBase={FaEdit}
        >
          <InfoWrapper>
            <BlockSquare
              width={'100%'}
              title={feed.name}
              content={t('basic_info_feed_name')}
              bottomBorder={true}
            />

            <BlockSquare
              width={'100%'}
              title={brands.findBrandNameById(feed.groupId)}
              content={t('basic_info_brand')}
              bottomBorder={true}
            />
            <BlockSquare
              width={'50%'}
              title={feed.country}
              content={t('basic_info_country')}
            />
            <BlockSquare
              width={'50%'}
              title={feed.enabled ? t('active') : t('not_active')}
              content={t('status')}
            />
          </InfoWrapper>

          {feed.job.finishedAt && (
            <Fragment>
              <Separator />
              <InfoWrapper>
                <BlockSquare
                  width={'100%'}
                  title={
                    feed.job && feed.job.finishedAt
                      ? t('dateValueInput', {
                          value: new Date(feed.job.finishedAt),
                        })
                      : '-'
                  }
                  content={t('outgoing_last_published')}
                  bottomBorder={true}
                />
                <BlockSquare
                  width={'33.3%'}
                  title={
                    feed.job.finishedAt
                      ? feedJobDurationToString(feed.job)
                      : '-'
                  }
                  content={t('overview_duration')}
                  bottomBorder={false}
                />
                <BlockSquare
                  width={'33.3%'}
                  title={
                    feed.job && feed.job.incoming && feed.job.incoming.stats
                      ? t('numericValueInput', {
                          value: feed.job.incoming.stats.totalProductCount,
                        })
                      : '-'
                  }
                  content={t('overview_number_of_products_downloaded')}
                />
                <BlockSquare
                  width={'33.3%'}
                  title={
                    feed.job && feed.job.incoming && feed.job.incoming.stats
                      ? t('numericValueInput', {
                          value:
                            feed.job.incoming.stats.totalProductCount -
                            feed.job.incoming.stats
                              .publishedProductProductCount,
                        })
                      : '-'
                  }
                  content={t('overview_number_of_products_excluded')}
                />
              </InfoWrapper>
            </Fragment>
          )}
        </Block>

        <Block title={t('overview_quick_jump')} width="350px">
          <BlockRow padding="8px 0 0 0">
            <Link link="../incoming">
              <FontRow fontSize={TEXT_BIG}>
                <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                  <span>
                    {t('incoming_products', {
                      productCount: feed.incoming ? feed.incoming.length : 0,
                    })}
                  </span>
                </HoverLinkDiv>
              </FontRow>
            </Link>
          </BlockRow>
          {feed.incoming && feed.incoming.map(CreateEditRow(false))}
          <BlockRow padding="8px 0 0 0">
            <Link link="../outgoing">
              <FontRow fontSize={TEXT_BIG}>
                <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                  <span>
                    {t('outgoing_sources', {
                      productCount: feed.outgoing ? feed.outgoing.length : 0,
                    })}
                  </span>
                </HoverLinkDiv>
              </FontRow>
            </Link>
          </BlockRow>
          {feed.outgoing && feed.outgoing.map(CreateEditRow(true))}
          <br />
          <Separator />
          <BlockRow padding="8px 0 0 0">
            <Link link="../alerts">
              <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                <EditRow>
                  <FontRow fontSize={TEXT_BIG}>
                    {t('Alerts')}{' '}
                    {feed.alerts && feed.alerts.length > 0
                      ? `(${feed.alerts.length})`
                      : ''}
                  </FontRow>
                  <FontRow fontSize={TEXT_MEDIUM}>
                    <FaEdit size={ICON_SIZE_SMALL} />
                  </FontRow>
                </EditRow>
              </HoverLinkDiv>
            </Link>
            <Link link="../exclusions">
              <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                <EditRow>
                  <FontRow fontSize={TEXT_BIG}>{t('Exclusions')}</FontRow>
                  <FontRow fontSize={TEXT_MEDIUM}>
                    <FaEdit size={ICON_SIZE_SMALL} />
                  </FontRow>
                </EditRow>
              </HoverLinkDiv>
            </Link>
            <Link link="../rules">
              <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                <EditRow>
                  <FontRow fontSize={TEXT_BIG}>{t('Transformations')}</FontRow>
                  <FontRow fontSize={TEXT_MEDIUM}>
                    <FaEdit size={ICON_SIZE_SMALL} />
                  </FontRow>
                </EditRow>
              </HoverLinkDiv>
            </Link>
            <Link link="../enrichments">
              <HoverLinkDiv color={VERMILION} hoverColor={METAL}>
                <EditRow>
                  <FontRow fontSize={TEXT_BIG}>{t('Enrichments')}</FontRow>
                  <FontRow fontSize={TEXT_MEDIUM}>
                    <FaEdit size={ICON_SIZE_SMALL} />
                  </FontRow>
                </EditRow>
              </HoverLinkDiv>
            </Link>
          </BlockRow>
        </Block>
      </Wrapper>
    </Fragment>
  )
}

Overview.propTypes = {
  feed: object,
  feedId: string.isRequired,
  organisationId: string.isRequired,
}

export default Overview
