import React from 'react'
import { string } from 'prop-types'
import { METAL, VERMILION } from '../../../../constants'

const FaTypeEnrichments = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width="39"
    height="39"
    viewBox="0 0 39 39"
  >
    <g
      id="Enrichment_List_Icon"
      data-name="Enrichment List Icon"
      transform="translate(-1196 -235)"
    >
      <g
        id="Rectangle_1684"
        data-name="Rectangle 1684"
        transform="translate(1196 235)"
        fill="none"
        stroke={METAL}
        strokeWidth="2"
      >
        <rect width="39" height="39" rx="5" stroke="none" />
        <rect x="1" y="1" width="37" height="37" rx="4" fill="none" />
      </g>
      <g
        id="Group_1189"
        data-name="Group 1189"
        transform="translate(-100.5 1.5)"
      >
        <line
          id="Line_167"
          data-name="Line 167"
          x2="8"
          transform="translate(1304.5 244.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_168"
          data-name="Line 168"
          x2="8"
          transform="translate(1304.5 250.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_169"
          data-name="Line 169"
          x2="8"
          transform="translate(1304.5 256.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_170"
          data-name="Line 170"
          x2="8"
          transform="translate(1304.5 262.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <line
        id="Line_167-2"
        data-name="Line 167"
        x2="8"
        transform="translate(1219 246)"
        fill="none"
        stroke={METAL}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_168-2"
        data-name="Line 168"
        x2="8"
        transform="translate(1219 252)"
        fill="none"
        stroke={METAL}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_169-2"
        data-name="Line 169"
        x2="8"
        transform="translate(1219 261)"
        fill="none"
        stroke={VERMILION}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Line_170-2"
        data-name="Line 170"
        x2="8"
        transform="translate(1223 257) rotate(90)"
        fill="none"
        stroke={VERMILION}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

FaTypeEnrichments.propTypes = {
  color: string,
}

export default FaTypeEnrichments
