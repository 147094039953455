import styled from 'styled-components'

import { METAL } from '../../../constants'
import '@fontsource/dm-sans/'

const Heading = styled.h1`
  font-family: 'DM Sans';
  font-size: 22px;
  color: ${METAL};
  margin: 10px 0 0;
`

export default Heading
