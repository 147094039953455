import React from 'react'
import styled from 'styled-components'
import { bool, func, node, string } from 'prop-types'

import {
  WHITE,
  GREY,
  MIDDLE_GREY,
  BUTTON_PADDING_REGULAR,
  TEXT_MEDIUM,
} from '../../../constants/'
import { PRIMARY_BUTTON } from '../../../constants/buttonThemes'
import { buttonThemeShape } from '../../../types'

import Link from '../Link'

const StyledButton = styled.button`
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize};
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.textColor};
  padding: ${({ padding }) => padding};
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
  border-radius: 5px
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ transparent }) => !transparent && GREY};
    color: ${MIDDLE_GREY};
  }

  &:hover:not([disabled]) {
    opacity: 1;
    background: ${({ theme }) =>
      theme.backgroundColorHover && theme.backgroundColorHover};
    color: ${({ theme, transparent }) =>
      transparent && (theme.textColorHover || WHITE)};
  }
`

const Button = ({
  children,
  fontSize = TEXT_MEDIUM,
  disabled,
  link,
  dataId,
  onClick,
  padding = BUTTON_PADDING_REGULAR,
  theme = PRIMARY_BUTTON,
  type = 'button',
  ...props
}) => {
  if (link) {
    return (
      <Link link={link} dataId={dataId}>
        <StyledButton
          fontSize={fontSize}
          data-id={dataId}
          padding={padding}
          onClick={onClick}
          theme={theme}
          textColorHover={theme.textColorHover}
          transparent={theme.backgroundColor === 'transparent'}
          disabled={disabled}
          type={type}
          {...props}
        >
          {children}
        </StyledButton>
      </Link>
    )
  } else {
    return (
      <StyledButton
        fontSize={fontSize}
        data-id={dataId}
        padding={padding}
        onClick={onClick}
        theme={theme}
        transparent={theme.backgroundColor === 'transparent'}
        disabled={disabled}
        type={type}
        {...props}
      >
        {children}
      </StyledButton>
    )
  }
}

Button.propTypes = {
  children: node.isRequired,
  disabled: bool,
  link: string,
  onClick: func,
  dataId: string,
  padding: string,
  theme: buttonThemeShape,
  type: string,
}

export default Button
