import React, { Fragment } from 'react'
import { arrayOf, func, string, object } from 'prop-types'

import downloadFile from '../../../lib/downloadFile'
import { outgoingShape, templateShape } from '../../../types'
import { APP_STATE, CARD_HEIGHT_LARGE, TEXT_BIG } from '../../../constants'
import { imagesUrl } from '../../../constants/urls'

import Card from '../../shared/Card'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import ContentWrapper from '../../shared/ContentWrapper'
import CardGridWrapper from '../../shared/CardGridWrapper'
import Text from '../../shared/Text'
import styled from 'styled-components'

const Spacer = styled.div`
  padding-top: 10px;
`
const calcPercentage = ({ publishedProductCount, totalProductCount }) =>
  publishedProductCount && totalProductCount
    ? Math.round((publishedProductCount / totalProductCount) * 100)
    : 0

const OutgoingCards = ({
  feedId,
  onPublishAll,
  onPauseAll,
  onPublishSelected,
  outgoingListState,
  outgoing,
  templates,
  organisationId,
  notifications,
  t,
}) => {
  const imageDictionary = (templates || []).reduce((acc, templ) => {
    acc[templ.id] = `${imagesUrl}${templ.imageUrl}`
    return acc
  }, {})

  const calcStatus = ({ enabled, publish }) => {
    if (enabled) {
      if (publish) return 'active'
      return 'test'
    }
    return 'paused'
  }
  return (
    <Fragment>
      <PageDescription
        heading={t('outgoing')}
        description={t('outgoing_desc')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            'data-id': 'new-outgoing',
            name: 'new_outgoing',
            tooltip: t('outgoing_button_new_outgoing_tooltip'),
            link: `/${organisationId}/feeds/${feedId}/outgoing/new`,
          },
          {
            name: 'outgoing_publish_active',
            onClick: () => onPublishAll(),
            tooltip: t('outgoing_button_publish_all_tooltip'),
            disabled: outgoing && !outgoing.length,
          },
          {
            name: 'button_pause_all',
            onClick: () => onPauseAll(),
            tooltip: t('outgoing_button_pause_all_tooltip'),
            disabled: outgoing && !outgoing.length,
          },
        ]}
      />
      <Spacer />
      {outgoingListState === APP_STATE.DONE && (
        <ContentWrapper>
          <CardGridWrapper>
            {outgoing.length === 0 && (
              <Text size={TEXT_BIG}>{t('no_outgoings_available')}</Text>
            )}
            {outgoing.map(
              ({
                id,
                detailUrl,
                name,
                job,
                enabled,
                publish,
                partnerId,
                format,
              }) => (
                <Card
                  alertLink={`/${organisationId}/reports`}
                  alertCount={
                    notifications &&
                    notifications.filter(x => x.data.outgoingId === id).length
                  }
                  key={id}
                  height={CARD_HEIGHT_LARGE}
                  status={calcStatus({ enabled, publish })}
                  img={imageDictionary[partnerId]}
                  title={name}
                  progress={{
                    percentage: calcPercentage({
                      totalProductCount:
                        job && job.stats ? job.stats.totalProductCount : 0,
                      publishedProductCount:
                        job && job.stats ? job.stats.publishedProductCount : 0,
                    }),
                    label: t('feed_published'),
                  }}
                  column={[
                    {
                      heading: t('number_of_products'),
                      description:
                        job && job.stats && job.stats.publishedProductCount > 0
                          ? t('numericValueInput', {
                              value: job.stats.publishedProductCount,
                            })
                          : t('not_available'),
                    },
                    {
                      heading: t('outgoing_last_published'),
                      tooltip: t('outgoing_last_published_tooltip'),
                      description:
                        job && job.stats
                          ? t('dateValueInput', {
                              value: new Date(job.stats.finishedAt),
                            })
                          : t('not_available'),
                    },
                  ]}
                  buttons={[
                    {
                      name: t('button_publish'),
                      tooltip: t('outgoing_button_publish_tooltip'),
                      onClick: () => onPublishSelected(feedId, id),
                    },
                    {
                      name: t('button_edit'),
                      tooltip: t('outgoing_button_edit_tooltip'),
                      link: detailUrl,
                    },
                    {
                      name: t('button_download'),
                      tooltip: t('outgoing_button_last_publish_tooltip'),
                      onClick: () =>
                        downloadFile(
                          t,
                          `/jobs/${job.id}/outgoing/${id}/data`,
                          `${name}.${format.type}`,
                          format.type
                        ),
                      disabled: !job.id,
                    },
                  ]}
                />
              )
            )}
          </CardGridWrapper>
        </ContentWrapper>
      )}
    </Fragment>
  )
}

OutgoingCards.propTypes = {
  feedId: string.isRequired,
  onPublishAll: func.isRequired,
  onPauseAll: func,
  onPublishSelected: func.isRequired,
  outgoingListState: string,
  notifications: arrayOf(object),
  outgoing: arrayOf(outgoingShape).isRequired,
  organisationId: string.isRequired,
  templates: arrayOf(templateShape),
}

export default OutgoingCards
