import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { string, func, bool, object } from 'prop-types'
import { navigate } from '@reach/router'
import ModalWindow from '../shared/ModalWindow'
import Input from './Input'
import Button from './Button'
import Form from './Form'
import { WHITE, SHADOW_GREY, METAL } from '../../constants'
import { PRIMARY_BUTTON, SECONDARY_BUTTON } from '../../constants/buttonThemes'
import { brandShape } from '../../types'
import '@fontsource/dm-sans/900.css' // Defaults to weight 400
import userOrganisations from './system/useOrganisations.js'

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 10px;
  }
`

const FormRow = styled.div`
  background: ${WHITE};
  display: grid;
  grid-template-columns: ${({ isOrganisationList }) =>
    isOrganisationList ? '1fr 1fr' : '1fr 1fr 1fr'};
  grid-gap: 15px;
  padding: 16px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  border-radius: 5px;
  font-family: Dm Sans;
  color: ${METAL};
  box-shadow: ${SHADOW_GREY};
  align-items: center;
`

const EditableRow = ({
  deleteRow,
  dispatch,
  editRow,
  hasImageLink,
  hasImageUrl,
  isOrganisationList,
  validation,
  organisationId,
  row: { id, name, imageLink, imageUrl },
  scope,
  t,
  userId,
  deleteConformationMessage,
}) => {
  const [isEditable, setEditable] = useState(false)
  const { list, setSelected: setOrg } = userOrganisations()
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const setOrganisation = id => setOrg(list.find(x => x.id === id))

  return (
    <Form
      initialValues={{
        name: name,
        imageLink: imageLink,
        imageUrl: imageUrl,
      }}
      onSubmit={values => {
        editRow(organisationId, id, t, values, dispatch, userId, scope)
        setEditable(false)
      }}
      validationSchema={validation}
      content={() => (
        <FormRow isOrganisationList={isOrganisationList}>
          {isEditable && (
            <Fragment>
              <Input name="name" data-id="new-row-name" noMargin />
              {hasImageLink && (
                <Input
                  name="imageLink"
                  data-id="new-row-imageLink"
                  maximumLength={500}
                />
              )}
              {hasImageUrl && (
                <Input
                  name="imageUrl"
                  data-id="new-row-imageUrl"
                  maximumLength={500}
                />
              )}
              <ButtonsContainer>
                <Button
                  theme={SECONDARY_BUTTON}
                  onClick={() => setEditable(false)}
                >
                  {t('button_cancel')}
                </Button>
                <Button
                  data-id="editable-row-save-button"
                  onClick={() => editRow}
                  type="submit"
                >
                  {t('button_save').toUpperCase()}
                </Button>
              </ButtonsContainer>
            </Fragment>
          )}
          {!isEditable && (
            <Fragment>
              <div data-id={name}>{name}</div>
              {hasImageLink && <div>{imageLink || ''}</div>}
              {hasImageUrl && <div>{imageUrl || ''}</div>}
              <ButtonsContainer>
                {isOrganisationList && (
                  <Button
                    theme={SECONDARY_BUTTON}
                    dataId={`${name}-users`}
                    onClick={() => setOrganisation(id)}
                    link={`/${id}/admin/users`}
                  >
                    {t('users').toUpperCase()}
                  </Button>
                )}
                <Button
                  dataId={`${name}-edit`}
                  theme={SECONDARY_BUTTON}
                  onClick={() => setEditable(true)}
                >
                  {t('button_edit').toUpperCase()}
                </Button>
                {deleteRow && (
                  <Fragment>
                    <Button
                      theme={SECONDARY_BUTTON}
                      dataId={`${name}-edit-mapping`}
                      onClick={() =>
                        navigate(`/system/add-outgoing-partner/${id}`)
                      }
                    >
                      {t('button_edit_mapping').toUpperCase()}
                    </Button>
                    <Button
                      theme={SECONDARY_BUTTON}
                      dataId={`${name}-delete`}
                      onClick={() => setDeleteDialogVisible(true)}
                    >
                      {t('button_delete').toUpperCase()}
                    </Button>
                    <ModalWindow
                      visible={deleteDialogVisible}
                      okText={t('button_delete')}
                      onOk={() =>
                        deleteRow(id, organisationId, dispatch, t, scope)
                      }
                      okTheme={PRIMARY_BUTTON}
                      onCancel={() => setDeleteDialogVisible(false)}
                      cancelTheme={SECONDARY_BUTTON}
                      t={t}
                    >
                      {deleteConformationMessage}
                    </ModalWindow>
                  </Fragment>
                )}
              </ButtonsContainer>
            </Fragment>
          )}
        </FormRow>
      )}
    />
  )
}

EditableRow.propTypes = {
  deleteRow: func,
  editRow: func.isRequired,
  hasImageLink: bool,
  hasImageUrl: bool,
  isOrganisationList: bool,
  validation: object,
  organisationId: string.isRequired,
  row: brandShape.isRequired,
  scope: string,
  userId: string,
}

export default EditableRow
