import React, { Fragment } from 'react'
import { func, arrayOf, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import CardList from '../../shared/CardList'
import PageDescription from '../../shared/PageDescription'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import RemoveModalWindow from '../../shared/RemoveModalWindow'
import ColumnGridWrapper from '../../shared/ColumnGridWrapper'
import Button from '../../shared/Button'

import { ruleShape, outgoingShape, simplePartnerShape } from '../../../types'
import {
  LIGHT_GREY,
  MIDDLE_GREY,
  WHITE,
  CARD_HEIGHT_SMALL,
  PLATINUM,
  SILVER,
  METAL,
} from '../../../constants'
import { SECONDARY_BUTTON } from '../../../constants/buttonThemes'

const RuleList = ({
  addToClipboard,
  inputList,
  outputList,
  selectedIncoming,
  selectedOutgoing,
  onRemove,
  onPause,
  onPositionChange,
  handleDeleteDialogVisibility,
  isDeleteDialogVisible,
}) => {
  const { t } = useTranslation()

  const onCopyAll = isOutgoing => {
    const atc = id => addToClipboard(id, isOutgoing && selectedOutgoing.id)
    const list = isOutgoing ? outputList : inputList
    atc(list.map(x => x.id))
  }

  const CopyAllButton = isOutgoing => {
    if (isOutgoing && (!outputList || !outputList.length > 0)) return
    if (!isOutgoing && (!inputList || !inputList.length > 0)) return

    return (
      <Button theme={SECONDARY_BUTTON} onClick={() => onCopyAll(isOutgoing)}>
        {t('button_copy_all')}
      </Button>
    )
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('transformations_heading')}
        description={t('transformations_description')}
      />
      <ColumnGridWrapper>
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_master_transformation',
              link: `new/master`,
              tooltip: t('rules_button_new_master_tooltip'),
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_partner_transformation',
              link: `new/outgoing/${selectedOutgoing.id}`,
              tooltip: t('rules_button_new_partner_tooltip'),
            },
          ]}
        />

        <CardList
          addToClipboard={addToClipboard}
          cardHeight={CARD_HEIGHT_SMALL}
          color={PLATINUM}
          textColor={METAL}
          description={t('master_transformations')}
          incomingId={selectedIncoming.id}
          list={inputList}
          name={t('master_feed')}
          onPause={onPause}
          onPositionChange={onPositionChange}
          productNumberlabel={t('transformed')}
          titleDescription={t('transformations')}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          side={CopyAllButton(false)}
          isRule={true}
        />

        <CardList
          addToClipboard={addToClipboard}
          cardHeight={CARD_HEIGHT_SMALL}
          color={SILVER}
          description={t('partner_transformations')}
          isPartner
          list={outputList}
          name={selectedOutgoing.name}
          onPause={onPause}
          onPositionChange={onPositionChange}
          outgoingId={selectedOutgoing.id}
          partnerId={selectedOutgoing.id}
          productNumberlabel={t('transformed')}
          textColor={WHITE}
          titleDescription={t('transformations')}
          handleDeleteDialogVisibility={handleDeleteDialogVisibility}
          isDeleteDialogVisible={isDeleteDialogVisible}
          side={CopyAllButton(true)}
          isRule={true}
        />

        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_master_transformation',
              link: `new/master`,
            },
          ]}
        />
        <TopButtonsWrapper
          buttons={[
            {
              name: 'add_partner_transformation',
              link: `new/outgoing/${selectedOutgoing.id}`,
            },
          ]}
        />
      </ColumnGridWrapper>

      <RemoveModalWindow
        t={t}
        handleDeleteDialogVisibility={handleDeleteDialogVisibility}
        onRemove={onRemove}
        isDeleteDialogVisible={isDeleteDialogVisible}
      />
    </Fragment>
  )
}

RuleList.propTypes = {
  addToClipboard: func.isRequired,
  inputList: arrayOf(ruleShape),
  outputList: arrayOf(ruleShape),
  selectedOutgoing: outgoingShape.isRequired,
  onRemove: func.isRequired,
  onPause: func.isRequired,
  onPositionChange: func.isRequired,
  selectedIncoming: simplePartnerShape.isRequired,
  handleDeleteDialogVisibility: func,
  isDeleteDialogVisible: bool,
}

export default RuleList
