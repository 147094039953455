import React from 'react'
import styled from 'styled-components'
import { node } from 'prop-types'

import { SHADOW_GREY, DARK_BLUE, WHITE, TEXT_SMALL } from '../../../constants'

import Text from '../Text'

export const TooltipWrapper = styled.div`
  display: none;
  background: ${DARK_BLUE};
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%);
  padding: 1px 3px;
  border-radius: 3px;
  box-shadow: ${SHADOW_GREY};
  z-index: 1;

  &::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translate(-50%);
    border: 13px solid;
    border-color: ${DARK_BLUE} transparent transparent transparent;
  }
`

const Tooltip = ({ children }) => (
  <TooltipWrapper>
    <Text color={'WHITE'} size={TEXT_SMALL}>
      {children}
    </Text>
  </TooltipWrapper>
)

Tooltip.propTypes = {
  children: node.isRequired,
}

export default Tooltip
