import React from 'react'
import styled from 'styled-components'
import { node } from 'prop-types'

import ContentWrapper from './ContentWrapper'
import ErrorBoundary from './ErrorBoundary'

import { WHITE, SHADOW_GREY } from '../../constants'

const FormContentWrapper = styled.div`
  background: ${WHITE};
  box-shadow: ${SHADOW_GREY};
  padding: 20px;
  border-radius: 5px;
`

const FormWrapper = ({ children }) => (
  <ContentWrapper>
    <FormContentWrapper>
      <ErrorBoundary>{children}</ErrorBoundary>
    </FormContentWrapper>
  </ContentWrapper>
)

FormWrapper.propTypes = {
  children: node.isRequired,
}

export default FormWrapper
