import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { string } from 'prop-types'
import { navigate } from '@reach/router'
import ModalWindow from '../../shared/ModalWindow'
import ContentWrapper from '../../shared/ContentWrapper'
import Loader from '../../shared/Loader'
import TopButtonsWrapper from '../../shared/TopButtonsWrapper'
import PageDescription from '../../shared/PageDescription'
import StructuredTable from '../../shared/StructuredTable'
import Button from '../../shared/Button'
import { TEXT_SMALL } from '../../../constants'
import {
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
} from '../../../constants/buttonThemes'
import styled from 'styled-components'
import { fetchInvitations, removeInvitation } from './actions'
import { initialState, reducer } from './reducer'

const Spacer = styled.div`
  padding-top: 10px;
`
const RowRadius = styled.div`
  .ReactTable .rt-tbody .rt-tr-group {
    border-radius: 5px;
  }
`
var selectedId = ''

const TableColumns = (
  t,
  organisationId,
  dispatch,
  page,
  deleteDialogVisible,
  setDeleteDialogVisible
) => [
  {
    Header: t('invitations_table_header1'),
    accessor: 'email',
  },
  {
    Header: t('invitations_table_header2'),
    accessor: 'createdAt',
    Cell: row => {
      const {
        original: { createdAt },
      } = row
      return createdAt
        ? t('dateValueInput', {
            value: new Date(createdAt),
          })
        : ''
    },
  },
  {
    Cell: row => {
      const {
        original: { id },
      } = row
      return (
        <>
          <Button
            dataId={`remove-invitation-${id}`}
            onClick={() => {
              selectedId = id
              setDeleteDialogVisible(true)
            }}
            theme={SECONDARY_BUTTON}
            fontSize={TEXT_SMALL}
          >
            {t('button_remove').toUpperCase()}
          </Button>
          <ModalWindow
            visible={deleteDialogVisible}
            okText={t('button_delete')}
            onOk={() => {
              removeInvitation(organisationId, selectedId, t, dispatch, page)
              selectedId = ''
            }}
            okTheme={PRIMARY_BUTTON}
            onCancel={() => {
              setDeleteDialogVisible(false)
              selectedId = ''
            }}
            cancelTheme={SECONDARY_BUTTON}
            t={t}
          >
            {t('user_delete_confirmation')}
          </ModalWindow>
        </>
      )
    },
  },
]

const InvitationList = ({ t, organisationId }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { invitationsList, invitationListState, pageTotal, page } = state
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  const filteredOrganisation = window.location.search.split('usersOf=')[1]

  useEffect(
    () => {
      fetchInvitations(organisationId, dispatch, page, t, filteredOrganisation)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId]
  )

  if (!invitationListState || invitationListState === 'FETCHING') {
    return <Loader />
  }

  return (
    <Fragment>
      <PageDescription
        heading={t('invitations')}
        description={t('invitations_description')}
      />
      <TopButtonsWrapper
        buttons={[
          {
            name: t('invite_user'),
            onClick: () => navigate(`/${organisationId}/admin/add-new-user`),
          },
        ]}
      />
      <Spacer />
      <ContentWrapper>
        <RowRadius>
          <StructuredTable
            columns={TableColumns(
              t,
              organisationId,
              dispatch,
              page,
              deleteDialogVisible,
              setDeleteDialogVisible
            )}
            data={invitationsList}
            isUsersTable
            page={page}
            pageSize={10}
            pageTotal={pageTotal}
            selectNextPage={() =>
              fetchInvitations(organisationId, dispatch, page + 1, t)
            }
            selectPreviousPage={() =>
              fetchInvitations(organisationId, dispatch, page - 1, t)
            }
            showPagination={false}
            sortable={false}
          />
        </RowRadius>
      </ContentWrapper>
    </Fragment>
  )
}

InvitationList.propTypes = {
  organisationId: string.isRequired,
}

export default InvitationList
