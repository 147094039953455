import React from 'react'
import styled from 'styled-components'
import { any, string } from 'prop-types'

import {
  BLOCK_PADDING_REGULAR,
  METAL,
  SILVER,
  VERMILION,
  WHITE,
} from '../../../constants'
import Link from '../../shared/Link'
import { HoverLinkDiv } from '../HoverLinkDiv'

const BlockRow = styled.div`
  padding: ${({ padding }) => padding || BLOCK_PADDING_REGULAR};
  background: ${({ background }) => background || WHITE};
  ${({ border }) => border && `border-${border}: solid 1px ${SILVER}`}
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const Row = ({ children, border, padding, background, link }) => {
  return link != null ? (
    <Link link={link}>
      <HoverLinkDiv color={WHITE} hoverColor={METAL}>
        <BlockRow border={border} padding={padding} background={background}>
          {children}
        </BlockRow>
      </HoverLinkDiv>
    </Link>
  ) : (
    <BlockRow border={border} padding={padding} background={background}>
      {children}
    </BlockRow>
  )
}

Row.propTypes = {
  padding: string,
  children: any,
  border: string,
}

export default Row
