import React from 'react'
import styled from 'styled-components'

import { VERMILION, TEXT_SMALL, SILVER, METAL } from '../../../constants'
import { progressBarShape } from '../../../types'

import Text from '../Text'

const StyledBar = styled.div`
  height: 10px;
  border-radius: 5px;
  width: ${({ progress }) =>
    progress || progress === 0 ? `${progress}%` : '100%'};
  background: ${({ color }) => color};
`

const ProgressBar = ({ progress: { percentage, label } }) => (
  <div>
    <Text color={METAL} size={TEXT_SMALL}>
      {`${percentage}% ${label}`}
    </Text>
    <StyledBar color={`${SILVER}5A`}>
      <StyledBar color={VERMILION} progress={percentage} />
    </StyledBar>
  </div>
)

ProgressBar.propTypes = {
  progress: progressBarShape.isRequired,
}

export default ProgressBar
