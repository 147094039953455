import React, { useEffect, useReducer, useState } from 'react'
import { Router, Redirect, navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import reducer, { initialState } from './reducer'
import {
  fetchError,
  fetchUser,
  logoutUser,
  fetchFeeds,
  toggleFeedEnabled,
  fetchFeed,
  saveFeed,
  createFeed,
  loginUser,
  getToken,
} from './actions'
import { APP_STATE } from '../../constants'
import { apiUrl } from '../../constants/urls'

import Layout from '../Layout'
import FeedList from '../../pages/FeedListPage'
import FeedDetail from '../../pages/FeedDetailPage'
import System from '../../pages/SystemPage'
import Admin from '../../pages/AdminPage'
import Report from '../../pages/ReportPage'
import Login from '../../pages/LoginPage'
import UserSetting from '../../pages/UserSettingPage'
import Loader from '../shared/Loader'
import Button from '../shared/Button'
import MessageWindow from '../shared/MessageWindow'
import api from '../../lib/api'

import useOrganisations from '../shared/system/useOrganisations'
import useBrands from '../shared/system/useBrands'

export var setOutgoingId
export var setIncomingId

const App = () => {
  const { t } = useTranslation()
  const orgs = useOrganisations()
  const brandsService = useBrands()

  const token = getToken()
  let isUser = !!token
  let noUserParam = window.location.search.includes('error=access_denied')

  const [outgoing, setOutgoing] = useState(null)
  const [incoming, setIncoming] = useState(null)
  const [selectedFeed, setSelectedFeed] = useState(null)
  const [selectedOutgoing, setSelectedOutgoing] = useState(null)
  const [selectedIncoming, setSelectedIncoming] = useState(null)
  const [noExistingUser, setExistingUser] = useState(noUserParam)
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    feed,
    feedDetailError,
    feedDetailState,
    feedListError,
    feedListState,
    feeds,
    user,
  } = state

  useEffect(
    () => {
      isUser &&
        fetchUser(dispatch)
          .then(() => {
            fetchOrganisations()
          })
          .catch(err => console.log(err))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUser]
  )

  useEffect(
    () => {
      if (!orgs.selected) return
      Promise.all([
        fetchFeeds(dispatch, orgs.selected.id),
        brandsService.fetchBrands(orgs.selected.id),
      ]).then(() => {
        const pathname = window.location.pathname
        const splitPath = pathname.split('/')

        // do not redirect when on this sub pages - they are not affected by org
        if (pathname.endsWith('/user-setting') || pathname.includes('/system'))
          return

        const origOrg = splitPath[1].toLowerCase()
        splitPath[1] = orgs.selected.id.toLowerCase()
        const feedPath = `/${orgs.selected.id}/feeds`
        const originalPath = splitPath.join('/')

        const targetPath =
          splitPath[2] === 'feeds' && origOrg !== splitPath[1]
            ? feedPath
            : originalPath
        navigate(targetPath)
        window.scrollTo(0, 0)
        setSelectedFeed(null)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgs.selected]
  )

  useEffect(
    () => {
      if (!orgs.list || !feed) return

      if (selectedFeed !== feed) {
        setSelectedFeed(feed)
        setOutgoing(feed.outgoing)
        setIncoming(feed.incoming)

        // rethink
        feed.id &&
          brandsService.list &&
          brandsService.setSelected(
            brandsService.list.find(brand => brand.id === feed.groupId) ||
              brandsService.allBrandsOption
          )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [feed, brandsService.list, orgs.list]
  )

  useEffect(
    () => {
      if (!incoming) return
      const path = window.location.pathname.split('/')
      if (path[4] === 'incoming' && path[5]) {
        setSelectedIncomingById(path[5])
      } else {
        feed.incoming &&
          feed.incoming.length > 0 &&
          setSelectedIncoming(feed.incoming[0])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [incoming]
  )

  useEffect(
    () => {
      if (!outgoing) return
      const path = window.location.pathname.split('/')
      if (path[4] === 'outgoing' && path[5]) {
        setSelectedOutgoingById(path[5])
      } else {
        feed.outgoing &&
          feed.outgoing.length > 0 &&
          setSelectedOutgoing(feed.outgoing[0])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [outgoing]
  )

  // move this to useOrganisations once we finish UserContext (we can load this once we have users)
  const fetchOrganisations = () =>
    api
      .get(`${apiUrl}/users/me/orgs?limit=250`)
      .then(({ data }) => {
        if (data && data.length) {
          orgs.setList(data)
        } else {
          toast.warn(t('organisations_missing'))
          dispatch(fetchError('organisations missing'))
        }
      })
      .catch(err => {
        toast.error(t('organisations_error'))
        console.log(err)
      })

  const onPauseToggle = (feedId, value) =>
    toggleFeedEnabled(feedId, value, dispatch, orgs.selected.id)

  const filterFeeds = () =>
    feeds.filter(
      feed =>
        feed.groupId === (brandsService.selected && brandsService.selected.id)
    )

  const changeFeed = feedId => {
    if (feedId && feedId === 'new') {
      createFeed(dispatch)
    } else {
      const fetchFeedOrFeeds =
        feedId === brandsService.allBrandsOption.id || !feedId
          ? fetchFeeds(dispatch, orgs.selected.id)
          : fetchFeed(feedId, dispatch)

      fetchFeedOrFeeds.catch(() => {
        toast.error(t('feed_error'))
        navigate(`/${orgs.selected.id}/feeds`)
      })
    }
    const pathName = window.location.pathname
    const [url, orgId, part, oldFeedId, page] = pathName.split('/')

    if (oldFeedId !== feedId) {
      navigate([url, orgId, part, feedId, page].join('/'), {
        replace: true,
      })
    }
  }

  const setSelectedIncomingById = id => {
    if (!incoming) return
    const newSelectedIncoming = incoming.find(i => i.id === id)

    if (newSelectedIncoming) setSelectedIncoming(newSelectedIncoming)
    return newSelectedIncoming
  }

  const setSelectedOutgoingById = id => {
    if (!outgoing) return
    const newSelectedOutgoing = outgoing.find(o => o.id === id)

    if (newSelectedOutgoing) setSelectedOutgoing(newSelectedOutgoing)
    return newSelectedOutgoing
  }
  setIncomingId = setSelectedIncomingById
  setOutgoingId = setSelectedOutgoingById
  if (noUserParam) {
    return (
      <MessageWindow text={t('no_valid_user')}>
        <Button
          dataId="redirectLogin"
          onClick={() => {
            setExistingUser(!noExistingUser)
            navigate(`/login?returnUrl=${window.location.href.split('?')[0]}`)
          }}
        >
          {t('go_to_login')}
        </Button>
      </MessageWindow>
    )
  } else if (isUser === false) {
    if (window.location.pathname !== '/login') {
      navigate(`/login?returnUrl=${window.location.href.split('?')[0]}`)
    }
    return <Login path="login" loginUser={loginUser} t={t} />
  } else if (
    (!orgs.selected || !orgs.selected.id) &&
    feedListState !== APP_STATE.FETCHING
  ) {
    return (
      <MessageWindow text={t('no_organisations')}>
        <Button
          dataId="logout"
          onClick={() => {
            setExistingUser(!noExistingUser)
            logoutUser(dispatch)
            navigate(`/`)
          }}
        >
          {t('button_ok')}
        </Button>
      </MessageWindow>
    )
  } else if (feedListState === APP_STATE.FETCHING && !incoming && !outgoing) {
    return <Loader />
  } else
    return (
      <Layout
        changeFeed={changeFeed}
        changeOutgoing={setSelectedOutgoing}
        changeIncoming={setSelectedIncoming}
        feeds={filterFeeds()}
        isUser={isUser}
        logoutUser={() => logoutUser(dispatch)}
        outgoing={outgoing}
        incoming={incoming}
        selectedFeed={selectedFeed}
        selectedOutgoing={selectedOutgoing}
        selectedIncoming={selectedIncoming}
        user={user}
      >
        <Router primary={false}>
          <Redirect from="/" to={`/${orgs.selected.id}/feeds`} noThrow />
          <FeedList
            errorMsg={feedListError}
            feedListState={feedListState}
            feeds={feeds}
            onPauseToggle={onPauseToggle}
            path=":orgs.selected.id/feeds"
            selectedFeed={selectedFeed}
            selectedFeedGroupId={selectedFeed && selectedFeed.groupId}
            reloadFeeds={() => fetchFeeds(dispatch, orgs.selected.id)}
          />
          <FeedDetail
            changeFeed={changeFeed}
            dispatch={dispatch}
            errorMsg={feedDetailError}
            feed={selectedFeed}
            feedDetailState={feedDetailState}
            path=":orgs.selected.id/feeds/:feedId/*"
            reloadFeeds={() => fetchFeeds(dispatch, orgs.selected.id)}
            reloadCurrentFeed={() => fetchFeed(feed.id, dispatch, false)}
            saveFeed={saveFeed(dispatch)}
            selectedIncoming={selectedIncoming}
            selectedOutgoing={selectedOutgoing}
            incoming={incoming}
            outgoing={outgoing}
            changeIncoming={setSelectedIncomingById}
            changeOutgoing={setSelectedOutgoingById}
          />
          <Admin path=":orgs.selected.id/admin/*" />
          <Report
            path=":orgs.selected.id/reports/*"
            selectedFeed={selectedFeed}
          />
          <UserSetting
            dispatch={dispatch}
            fetchUser={fetchUser}
            path="user-setting"
            user={user}
          />
          <System path="system/*" userId={user.id} />

          <Redirect
            from="/"
            to={`/${orgs.selected.id}/feeds`}
            default
            noThrow
          />
        </Router>
      </Layout>
    )
}

export default App
