import React from 'react'
import { string } from 'prop-types'
import { METAL, VERMILION } from '../../../../constants'

const FaTypeList = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill={color}
  >
    <g id="List_Icon" data-name="List Icon" transform="translate(-1296 -235)">
      <g
        id="Rectangle_1682"
        data-name="Rectangle 1682"
        transform="translate(1296 235)"
        fill="none"
        stroke={METAL}
        strokeWidth="2"
      >
        <rect width="39" height="39" rx="5" stroke="none" />
        <rect x="1" y="1" width="37" height="37" rx="4" fill="none" />
      </g>
      <g id="Group_1188" data-name="Group 1188" transform="translate(0 1.5)">
        <line
          id="Line_167"
          data-name="Line 167"
          x2="22"
          transform="translate(1304.5 244.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_168"
          data-name="Line 168"
          x2="22"
          transform="translate(1304.5 250.5)"
          fill="none"
          stroke={VERMILION}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_169"
          data-name="Line 169"
          x2="22"
          transform="translate(1304.5 256.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Line_170"
          data-name="Line 170"
          x2="22"
          transform="translate(1304.5 262.5)"
          fill="none"
          stroke={METAL}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

FaTypeList.propTypes = {
  color: string,
}

export default FaTypeList
