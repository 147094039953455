import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { FaInfoCircle } from 'react-icons/fa'
import Tooltip from '../Tooltip2'
import { ICON_SIZE_SMALL, METAL } from '../../../constants'

const Wrapper = styled.span`
  width: auto;
  margin-left: 5px;
`

const IconWrapper = styled.span`
  display: inline;
  vertical-align: middle;
`

const TooltipIcon = ({ title, position }) => (
  <Wrapper>
    <Tooltip title={title} position={position} padding={0} radius={0}>
      <IconWrapper>
        <FaInfoCircle color={METAL} size={ICON_SIZE_SMALL} />
      </IconWrapper>
    </Tooltip>
  </Wrapper>
)

TooltipIcon.propTypes = {
  title: string,
  position: string,
}

export default TooltipIcon
