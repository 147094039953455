import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import {
  VERMILION,
  LARGE_RADIUS,
  BUTTON_PADDING_REGULAR,
  SHADOW_GREY,
} from '../../../constants'
import { TRANSPARENT_BUTTON } from '../../../constants/buttonThemes'
import { userShape } from '../../../types'

import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import { navigate } from '@reach/router'

const Container = styled.div`
  display: flex;
  align-items: right;
  width: 300px;
  overflow: 'hidden';
`

const UserAvatar = styled.img`
  max-width: 32px;
  max-height: 32px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: ${SHADOW_GREY};
`

const UserName = styled.label`
  padding: 0 16px;
  min-width: 100px;
  white-space: nowrap;
  cursor: pointer;
`

const UserNavigation = ({ user, logoutUser }) => (
  <Container>
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {' '}
      <UserAvatar
        src={user.avatarUrl}
        onClick={() => navigate('/user-setting')}
      />{' '}
    </div>
    <Button onClick={logoutUser} theme={TRANSPARENT_BUTTON}>
      <view>
        <Icon icon="logout" color={VERMILION} size="28" />
      </view>
    </Button>
  </Container>
)

UserNavigation.propTypes = {
  user: userShape,
  dispatch: func,
  logoutUser: func,
}

export default UserNavigation
