import React from 'react'
import styled from 'styled-components'
import { string, any } from 'prop-types'

import BlockRow from './Row.js'
import BlockContent from './Content.js'
import {
  SHADOW_GREY,
  WHITE,
  TEXT_BIG,
  LAPTOP_WIDTH,
  VERMILION,
} from '../../../constants'
import '@fontsource/dm-sans/400.css' // Defaults to weight 400

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  min-width: 240px;
  width: ${({ width }) => width || 'auto'};
  margin-right: 48px;
  margin-bottom: 24px;
  flex-direction: column;
  height: ${({ height }) => height};
  background: ${WHITE};
  border-radius: 5px;

  box-shadow: ${SHADOW_GREY};
  @media (max-width: ${LAPTOP_WIDTH}) {
    min-width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Dm Sans';
  font-size: ${TEXT_BIG};
  color: ${({ color }) => color};
`

const Block = ({
  title,
  link,
  linkText,
  IconBase,
  children,
  footer,
  width,
  padding,
}) => {
  return (
    <BlockWrapper width={width}>
      <BlockRow background={VERMILION} link={link}>
        <TitleWrapper color={link == null ? WHITE : 'inherit'}>
          <span fontFamily="Dm Sans" fontSize={TEXT_BIG}>
            {title}
          </span>
          {IconBase != null ? <IconBase /> : <></>}
        </TitleWrapper>
      </BlockRow>
      <BlockContent padding={padding}>{children}</BlockContent>
      {footer && <BlockRow border="top">Footer</BlockRow>}
    </BlockWrapper>
  )
}

Block.propTypes = {
  padding: string,
  children: any,
  title: string,
  link: string,
  linkText: string,
  footer: any,
  width: string,
}

export default Block
