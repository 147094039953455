import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { FaClock, FaCheckCircle, FaMinusCircle } from 'react-icons/fa'
import Button from '../shared/Button'
import Tooltip, { TooltipWrapper } from '../shared/Tooltip'
import Rectangle from '../shared/Rectangle'
import downloadFile from '../../lib/downloadFile'
import {
  WHITE,
  RED,
  TEXT_SMALL,
  VERMILION,
  SHADOW_GREY,
  METAL,
  ICON_SIZE_MEDIUM,
  MANTIS,
  YELLOW,
} from '../../constants'
import { SECONDARY_BUTTON } from '../../constants/buttonThemes'
import api from '../../lib/api'
import '@fontsource/dm-sans/'

const RectangleWrapper = styled.div`
  position: relative;

  &:hover {
    ${TooltipWrapper} {
      display: block;
      padding: 8px;
    }
  }
`

const CellCenter = styled.div`
  vertical-align: middle;
  display: table-cell;
  white-space: no-wrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

function TextNoWrap(text) {
  return (
    <CellCenter>
      <span
        fontSize={TEXT_SMALL}
        font-family="DM Sans"
        color={METAL}
        text-overflow="ellipsis"
      >
        {text}
      </span>
    </CellCenter>
  )
}

const ExpandArrowCircle = styled.div`
  background: ${props => props.$draggable || VERMILION};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  box-shadow: ${SHADOW_GREY};
  margin: 0 5px;
`

const ExpandArrow = styled.div`
  background: ${WHITE};
  height: 1px;
  width: 9px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  top: 11px;
  ${({ arrowDown }) => arrowDown && 'transform: rotate(90deg)'};

  &:before,
  &:after {
    content: '';
    background: ${WHITE};
    position: absolute;
    height: 1px;
    width: 7px;
  }

  &:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg);
  }
`

function JobStatusIcon(status, expand) {
  var jobStatusInformation = jobStatus[status]
  var color = expand ? WHITE : jobStatusInformation.color
  return (
    <div
      style={{
        marginRight: '5px',
        verticalAlign: 'middle',
        display: 'inline-block',
        position: 'relative',
        height: `${ICON_SIZE_MEDIUM}px`,
      }}
    >
      <jobStatusInformation.icon
        size={ICON_SIZE_MEDIUM}
        verticalAlign="middle"
        display="inline-block"
        color={color}
      />
    </div>
  )
}

const jobStatus = {
  failed: { color: RED, icon: FaMinusCircle },
  pending: { color: YELLOW, icon: FaClock },
  downloading: { color: YELLOW, icon: FaClock },
  merging: { color: YELLOW, icon: FaClock },
  transforming: { color: YELLOW, icon: FaClock },
  formatting: { color: YELLOW, icon: FaClock },
  uploading: { color: YELLOW, icon: FaClock },
  parsing: { color: YELLOW, icon: FaClock },
  running: { color: YELLOW, icon: FaClock },
  succeeded: { color: MANTIS, icon: FaCheckCircle },
  succeededWithoutPublishing: { color: MANTIS, icon: FaCheckCircle },
  cancelled: { color: RED, icon: FaMinusCircle },
}

const publish = (jobId, outgoingId, t) => {
  api
    .post(`jobs/${jobId}/publish`, { outgoingIds: [outgoingId] })
    .then(() => {
      toast.success(t('job_republish_success'))
    })
    .catch(() => {
      toast.success(t('job_republish_fail'))
      alert('naay!')
    })
}

const shouldDisableButton = status =>
  !(
    status &&
    ['succeeded', 'succeededWithoutPublishing', 'uploading'].includes(status)
  )

export const MainTableColumns = (t, calculateJobDuration, brands) => [
  {
    expander: true,
    width: 50,
    Expander: row => (
      <ExpandArrowCircle
        $draggable={row.isExpanded ? METAL : VERMILION}
        draggable="true"
      >
        {row.isExpanded ? <ExpandArrow arrowDown /> : <ExpandArrow />}
      </ExpandArrowCircle>
    ),
  },
  {
    expander: true,
    Header: t('brand'),
    width: 200,
    Expander: ({ original: { groupId: brandId } }) => {
      const selectedBrand = brands && brands.find(brand => brand.id === brandId)
      return selectedBrand ? TextNoWrap(selectedBrand.name) : TextNoWrap('')
    },
  },
  {
    expander: true,
    Header: t('feed'),
    width: 200,
    Expander: ({
      original: {
        feed: { name },
      },
    }) => TextNoWrap(name),
  },
  {
    Header: t('processing_queue_table_date_started'),
    expander: true,
    width: 250,
    Expander: row => {
      return row.original.startedAt
        ? TextNoWrap(
            t('dateValueInput', {
              value: new Date(row.original.startedAt),
            })
          )
        : ''
    },
  },
  {
    Header: t('processing_queue_table_duration'),
    expander: true,
    width: 100,
    Expander: ({ original: { startedAt, finishedAt } }) =>
      calculateJobDuration(startedAt, finishedAt),
  },
  {
    Header: t('status'),
    expander: true,
    width: 150,
    Expander: row => {
      const {
        original: { status, error },
      } = row
      const content = (
        <>
          {jobStatus[status] ? JobStatusIcon(status, row.isExpanded) : ' '}
          {TextNoWrap(t(status))}
        </>
      )
      return (
        <>
          {error && (
            <Tooltip>
              {t(
                error.code || '1000',
                error.messageParams && {
                  first: error.messageParams[1],
                  second: error.messageParams[2],
                }
              )}
            </Tooltip>
          )}
          {status === 'succeededWithoutPublishing' && (
            <Tooltip>{TextNoWrap(t(status))}</Tooltip>
          )}
          {content}
        </>
      )
    },
  },
  {
    Header: t('alerts'),
    expander: true,
    width: 50,
    Expander: row => {
      const {
        original: { notifications },
      } = row
      return notifications && <Rectangle>{notifications.length}</Rectangle>
    },
  },
  {
    Header: '',
    accessor: 'fileId',
    width: 100,
    Cell: row => {
      const {
        value,
        original: { name, stats, fileId },
      } = row
      if (value) {
        return (
          <Button
            onClick={() => downloadFile(t, `/files/${value}/data`, name, 'csv')}
            fontSize={TEXT_SMALL}
            theme={SECONDARY_BUTTON}
            disabled={!stats.totalProductCount || !fileId}
          >
            {t('button_download').toUpperCase()}
          </Button>
        )
      }
      return null
    },
  },
]

export const JobType = {
  INCOMING: 'incoming',
  MASTER: 'master',
  OUTGOING: 'outgoing',
}

export const SubTableColumns = (
  t,
  calculateDuration,
  getPercentage,
  type,
  masterName,
  jobId,
  notifications = []
) => [
  {
    Header: t(type),
    columns: [
      {
        accessor: 'name',
        width: 200,
        Cell: row => {
          if (row.value) {
            return TextNoWrap(row.value)
          } else return TextNoWrap(`${t(type)} - ${t(masterName)}`)
        },
      },
    ],
  },
  {
    Header: t('processing_queue_subtable_published'),
    columns: [
      {
        Header: t('processing_queue_subtable_products'),
        Cell: ({
          original: {
            stats: { totalProductCount, publishedProductCount },
          },
        }) => {
          if (publishedProductCount || publishedProductCount === 0)
            return publishedProductCount
          if (totalProductCount || totalProductCount === 0)
            return totalProductCount
          return '-'
        },
        width: 100,
      },
      {
        Header: t('processing_queue_subtable_percentage'),
        width: 100,
        Cell: ({
          original: {
            stats: { totalProductCount, publishedProductCount },
          },
        }) => getPercentage(publishedProductCount, totalProductCount),
      },
    ],
  },
  {
    Header: '',
    expander: true,
    width: 250,
    Expander: row => {
      const {
        original: { stats },
      } = row
      return stats && stats.startedAt
        ? t('dateValueInput', {
            value: new Date(stats.startedAt),
          })
        : ''
    },
  },
  {
    Header: '',
    expander: true,
    width: 100,
    Expander: ({ original: { status, stats } }) =>
      calculateDuration(status, stats),
  },
  {
    Header: '',
    expander: true,
    width: 150,
    Expander: row => {
      const {
        original: { status, error },
      } = row
      const content = (
        <>
          {jobStatus[status] ? JobStatusIcon(status, row.isExpanded) : ''}
          {TextNoWrap(t(status))}
        </>
      )
      return (
        <>
          {error && (
            <Tooltip>
              {t(
                error.code || '1000',
                error.messageParams && {
                  first: error.messageParams[1],
                  second: error.messageParams[2],
                }
              )}
            </Tooltip>
          )}
          {status === 'succeededWithoutPublishing' && (
            <Tooltip>{TextNoWrap(t(status))}</Tooltip>
          )}
          <>{content}</>
        </>
      )
    },
  },
  {
    Header: '',
    expander: true,
    width: 50,
    Expander: row => {
      let fun = x => {
        return (
          x.data.incomingId === undefined && x.data.outgoingId === undefined
        ) //master check
      }
      if (type === JobType.INCOMING)
        fun = x => x.data.incomingId && x.data.incomingId === row.original.id
      if (type === JobType.OUTGOING)
        fun = x => x.data.outgoingId && x.data.outgoingId === row.original.id

      const partNotifications = notifications.filter(fun)

      return (
        partNotifications &&
        partNotifications.length > 0 && (
          <RectangleWrapper>
            <Tooltip>
              {partNotifications.map((n, i) => (
                <>
                  <span key={i}>{t(`${n.type}`, n.data)}</span>
                  {i < partNotifications.length - 1 && <span>, </span>}
                </>
              ))}
            </Tooltip>
            <Rectangle>{partNotifications.length}</Rectangle>
          </RectangleWrapper>
        )
      )
    },
  },
  {
    Header: '',
    accessor: 'id',
    width: 175,
    Cell: row => {
      const { value, original } = row
      if (jobId) {
        return (
          <>
            <Button
              onClick={() => {
                const url = `/jobs/${jobId}/${type}/${
                  type === JobType.MASTER ? 'data' : `${value}/data`
                }`
                const fileName =
                  type === JobType.MASTER ? masterName : original.name
                const fileType =
                  type === JobType.OUTGOING ? original.stats.format.type : 'csv'
                downloadFile(t, url, fileName, fileType)
              }}
              fontSize={TEXT_SMALL}
              theme={SECONDARY_BUTTON}
              disabled={shouldDisableButton(original.status)}
            >
              {t('button_download').toUpperCase()}
            </Button>
            {type === JobType.OUTGOING && (
              <Button
                style={{ marginLeft: 10 }}
                fontSize={TEXT_SMALL}
                theme={SECONDARY_BUTTON}
                onClick={() => publish(jobId, original.id, t)}
                disabled={shouldDisableButton(original.status)}
              >
                {t('Upload')}
              </Button>
            )}
          </>
        )
      }
      return null
    },
  },
]
