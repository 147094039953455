import React from 'react'
import { arrayOf, func, string, bool } from 'prop-types'
import { connect, getIn } from 'formik'

import {
  formikShape,
  dropdownValueShape,
  formValueObject,
} from '../../../types'

import { VERMILION } from '../../../constants'
import hasFieldFormikError from '../../../lib/hasFieldFormikError'

import SelectWithOptions from './SelectWithOptions'

const Dropdown = connect(
  ({
    backgroundColor = 'transparent',
    boundValue,
    dataId,
    formik: { setFieldValue, values, errors, touched },
    formikChange,
    isHigh,
    isNarrow,
    label,
    name,
    noBorder,
    notNull,
    onChange,
    optionImageKey,
    optionNameKey = 'name',
    options,
    optionValueKey = 'id',
    placeholder,
    placeholderColor = VERMILION,
    value,
    disabled,
  }) => {
    const findInitialValue = () => {
      if (values) {
        const result = options.find(
          option => option[optionValueKey] === getIn(values, name)
        )
        return notNull && !result ? options[0] : result
      }

      return value ? value : null
    }

    const handleChange = selection => {
      if (setFieldValue) {
        setFieldValue(name, selection[optionValueKey])
        formikChange &&
          formikChange({
            target: { id: name, name, value: selection[optionValueKey] },
          })
      }
      if (onChange) {
        onChange(selection)
      }
    }

    // This part at least highlights the dropdown with a red rectange
    // when the value displayed is not a valid option from the dropdown
    let error = false
    if (value && options) {
      if (!options.find(({ id }) => id === value.id)) error = true
    }

    return (
      <SelectWithOptions
        backgroundColor={backgroundColor}
        dataId={dataId}
        findInitialValue={findInitialValue}
        handleChange={handleChange}
        isError={hasFieldFormikError(errors, touched, name) || error}
        isHigh={isHigh}
        isNarrow={isNarrow}
        label={label}
        name={name}
        noBorder={noBorder}
        optionImageKey={optionImageKey}
        optionNameKey={optionNameKey}
        options={options}
        optionValueKey={optionValueKey}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        selectedValue={boundValue}
        disabled={disabled}
      />
    )
  }
)

Dropdown.propTypes = {
  backgroundColor: string,
  boundValue: dropdownValueShape,
  dataId: string,
  formik: formikShape,
  formikChange: func,
  isNarrow: bool,
  label: string,
  name: string,
  noBorder: bool,
  notNull: bool,
  onChange: func,
  optionImageKey: string,
  optionNameKey: string,
  options: arrayOf(formValueObject).isRequired,
  optionValueKey: string,
  placeholder: string,
  placeholderColor: string,
  value: dropdownValueShape,
  disabled: bool,
}

Dropdown.displayName = 'Dropdown'

export default Dropdown
