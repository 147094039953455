import styled from 'styled-components'
import { FULL_MAX_CONTENT_WIDTH, PLATINUM, SHADOW_GREY } from '../../constants'

const ContentWrapper = styled.div`
  background: ${PLATINUM};
  padding: 30px 15px;
  border-radius: 5px
  width: 100%;
  max-width: ${FULL_MAX_CONTENT_WIDTH};
  margin-top: 15px;
  box-shadow: ${SHADOW_GREY};
`

export default ContentWrapper
